<template>
     <div class="mb-7 lg:mb-3">
          <div
               v-if="groupData != null && !loading"
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2"
          >
               <div
                    v-if="active != null"
                    :class="
                         active != null ? 'w-max opacity-100' : 'w-0 opacity-0'
                    "
                    class="transition-all ease-in-out duration-300"
               >
                    <div
                         @click="() => onSetActive(null)"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid border-moozi-4"
                    >
                         <span class="icon-allgames text-xl lg:text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              Lobby
                         </p>
                    </div>
               </div>
               <div
                    v-for="(item, key) in groupData"
                    :key="key + 6"
               >
                    <div
                         v-if="
                              (isUser &&
                                   ['recent', 'favorite'].includes(
                                        item.alias
                                   )) ||
                              !['recent', 'favorite'].includes(item.alias)
                         "
                         @click="onSetActive(item.alias)"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == item.alias
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <div
                              class="game-category-icon"
                              v-html="decodeIcon(item.icon)"
                         ></div>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              {{ item.name.toLowerCase() }}
                         </p>
                    </div>
               </div>
          </div>
          <div
               v-else
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2"
          >
               <div
                    v-for="key in 12"
                    :key="key"
               >
                    <Skeletor
                         as="div"
                         class="!w-32 lg:!w-44 !h-8 lg:!h-9 !rounded-full"
                    />
               </div>
          </div>
     </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { authStore } from "store/auth.js";
import { gamesStore } from "store/games";
import { useFormatter } from "composables/use-formatter.js";

export default {
     props: ["onSetActive", "active"],
     setup() {
          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const loading = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const groupData = computed(() => {
               return useGamesStore.groups || null;
          });

          watch(groupData, () => {
               loading.value = true;
               setTimeout(() => {
                    loading.value = false;
               }, 1500);
          });

          const getGameCategory = (categories, type) => {
               return type == "alias"
                    ? categories.replaceAll(" ", "-")
                    : categories;
          };

          return {
               isUser,
               loading,
               groupData,
               getGameCategory,
               decodeIcon
          };
     }
};
</script>
