<template>
     <div>
          <div class="flex flex-col mb-8 md:mb-14 -mt-2">
               <div class="flex flex-row items-center justify-between">
                    <div
                         v-if="
                              game_type == 'result'
                                   ? true
                                   : !floading && data?.data
                         "
                         class="flex flex-row items-center gap-2 text-white"
                         :class="{
                              'lobby-category-clickable cursor-pointer duration-300 ease-in-out hover:text-moozi-3':
                                   isLobby
                         }"
                         @click="isLobby ? onSetActive(game_type) : null"
                    >
                         <slot name="icon"></slot>
                         <p
                              class="font-roboto font-bold text-sm lg:text-lg capitalize"
                         >
                              {{ title }}
                         </p>
                    </div>
                    <Skeletor
                         v-else
                         as="div"
                         class="!w-32 lg:!w-44 !h-6 lg:!h-7 !rounded-md mx-1.5"
                    />
               </div>
               <div
                    v-if="!floading && data?.data?.length != 0"
                    class="mt-2"
               >
                    <div
                         class="grid grid-cols-3 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12 3xl:grid-cols-[repeat(13,_minmax(0,_1fr))] gap-3 mx-1.5 md:mx-0"
                    >
                         <div
                              v-for="item in data?.data"
                              :key="item.id"
                              :game-id="item.id"
                              @click="() => onRedirect(item)"
                         >
                              <div
                                   class="game-card bg-moozi-2 rounded-md w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             :alt="item.game_pic"
                                             class="broken-image absolute top-0 left-0 rounded-t-md w-full h-full game-thumbnails invisible"
                                        />
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                        >
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic != null &&
                                                       !loading
                                                  "
                                                  :src="item.game_pic"
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic_mobile !=
                                                            null && !loading
                                                  "
                                                  :src="item.game_pic_mobile"
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                             />
                                        </div>
                                   </div>
                                   <div
                                        class="flex flex-col items-start px-1 py-1 md:px-2 md:py-2 text-left text-white text-xxs sm:text-xs lg:text-base font-bold w-full space-y-1 cursor-pointer"
                                   >
                                        <div
                                             v-if="item.currency != 'SCC'"
                                             class="rounded-full coin-wrapper cursor-pointer w-full"
                                        >
                                             <div
                                                  class="flex items-center justify-start tracking-wide"
                                             >
                                                  <img
                                                       class="cursor-pointer w-2 xs:w-3 sm:w-4 lg:w-5"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="pl-1 truncate ... !text-white leading-tight"
                                                  >
                                                       {{ money3(item.amount) }}
                                                  </p>
                                             </div>
                                        </div>
                                        <div
                                             v-else
                                             class="rounded-full coin-wrapper cursor-pointer w-full"
                                        >
                                             <div
                                                  class="flex items-center justify-start tracking-wide"
                                             >
                                                  <img
                                                       class="cursor-pointer w-2 xs:w-3 sm:w-4 lg:w-5"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="pl-1 truncate ... !text-white leading-tight"
                                                  >
                                                       {{ money3(item.amount) }}
                                                  </p>
                                             </div>
                                        </div>
                                        <p
                                             class="!text-moozi-6 text-xxxs sm:text-mid lg:text-xs truncate ... w-full leading-tight font-semibold"
                                        >
                                             {{ item.currentuser }}
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="data?.data?.length == 0 && !floading"
                    class="mt-2"
               >
                    <div
                         class="bg-moozi-2 rounded-xl flex justify-center items-center font-roboto font-semibold text-sm lg:text-base text-moozi-4 py-5 md:py-10 px-4 md:px-10"
                    >
                         Sorry! No Winners Available
                    </div>
               </div>
               <div
                    v-else
                    class="mt-2"
               >
                    <div
                         class="grid grid-cols-3 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12 3xl:grid-cols-[repeat(13,_minmax(0,_1fr))] gap-3 mx-1.5 md:mx-0"
                    >
                         <div
                              v-for="key in 50"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             loading="lazy"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-preloader.png'
                                             "
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <Skeletor
                                             as="div"
                                             class="!absolute !top-0 !left-0 !rounded-md !w-full !h-full"
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, ref, onMounted } from "vue";
import { gamesStore } from "store/games";
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";
import { useRouter } from "vue-router";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {},
     props: [
          "data",
          "title",
          "onSeeMore",
          "pagination",
          "game_type",
          "onSetActive",
          "isLobby"
     ],
     setup() {
          const useGamesStore = gamesStore();
          const router = useRouter();
          const { money3 } = useFormatter();

          const { onImageError } = useImage();

          const floading = ref(false);

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const onRedirect = (data) => {
               openGamePreview(data, () => {
                    router.push({
                         path:
                              "/games/detail/" +
                              encodeURIComponent(
                                   data.game_name.replaceAll(" ", "-")
                              ) +
                              "-" +
                              data.id
                    });
               });
          };

          const openGamePreview = (data, onPlay) => {
               eventBus.emit("open:gamepreview", {
                    data: data,
                    onPlay: onPlay
               });
          };

          onMounted(() => {
               if (providerData.value == null) {
                    floading.value = true;
                    setTimeout(() => {
                         floading.value = false;
                    }, 3000);
               }
          });

          return {
               baseUrl,
               onImageError,
               floading,
               onRedirect,
               openGamePreview,
               money3
          };
     }
};
</script>
