export const baseUrl = {
     store: "",
     api: import.meta.env.VITE_APP_URL + "/api",
     apiUpload: "https://moshy.6mbr.com/api/",
     img: import.meta.env.VITE_APP_URL,
     url: import.meta.env.VITE_APP_URL
};

export const fiservUrl = {
     bank:
          import.meta.env.VITE_ENV == "production"
               ? "b8a76086-6257-4c51-b05a-023c4546519f"
               : "32678005-8e7f-4d12-8298-cd40e103866a",
     manual:
          import.meta.env.VITE_ENV == "production"
               ? "8f40f2fe-c981-44e7-917a-693917f"
               : "31b64180-6493-40a2-961f-0de4c72009ce",
     edit:
          import.meta.env.VITE_ENV == "production"
               ? "14c8aefd-1fd8-40e0-bd9f-c4d14811fec2"
               : "0a35f90a-f0a2-4daa-855a-f19588a0139a",
     close:
          import.meta.env.VITE_ENV == "production"
               ? "14f129ee-b7fd-4fc7-8be2-947ea43807fe"
               : "3c2ddbe2-3108-41ae-88f9-1aadfe6b4fe7"
};

export const eprotectUrl = {
     dev: {
          "eprotect-import":
               "https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js",
          "eprotect-jquery-import":
               "https://request.eprotect.vantivprelive.com/eProtect/js/jquery-1.11.2.js",
          paypageId: "3S5cjTtQhLUCGEW2",
          style: "mooziwpnewest"
     },
     prod: {
          "eprotect-import":
               "https://request.eprotect.vantivcnp.com/eProtect/js/eProtect-iframe-client4.min.js",
          "eprotect-jquery-import":
               "https://request.eprotect.vantivcnp.com/eProtect/js/jquery-1.11.2.js",
          paypageId: "Jt2oyPfdW9tRjuMK",
          style: "mooziwpnewest"
     }
};

export const configureWorldPay = (
     data,
     inputsEmptyCallback,
     payframeClientCallback
) => {
     return {
          paypageId: data["paypageId"],
          height: "300px",
          width: "100%",
          style: data["style"],
          reportGroup: document.getElementById("request$reportGroup").value,
          timeout: document.getElementById("request$timeout").value,
          div: "payframe",
          callback: payframeClientCallback,
          showCvv: true,
          noScrollBar: true,
          months: {
               1: "January",
               2: "February",
               3: "March",
               4: "April",
               5: "May",
               6: "June",
               7: "July",
               8: "August",
               9: "September",
               10: "October",
               11: "November",
               12: "December"
          },
          numYears: 8,
          tooltipText:
               "A CVV is the 3 digit code on the back of your Visa, MasterCard and Discover or a 4 digit code on the front of your American Express",
          tabIndex: {
               accountNumber: 1,
               cvv: 2,
               expMonth: 3,
               expYear: 4
          },
          placeholderText: {
               cvv: "CVV",
               accountNumber: "Account Number"
          },
          htmlTimeout: document.getElementById("request$timeout").value,
          inputsEmptyCallback: inputsEmptyCallback,
          clearCvvMaskOnReturn: false,
          enhancedUxFeatures: {
               inlineFieldValidations: true,
               numericInputsOnly: false,
               enhancedUxVersion: 2
          }
     };
};
