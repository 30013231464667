<template>
     <InertiaHead title="Gold Coin Shop"></InertiaHead>
     <div class="w-full h-full relative mt-0">
          <div class="w-full bg-transparent relative">
               <div
                    v-if="isSelfExclusion == null"
                    class="w-full h-full flex flex-col justify-center items-center pb-4 sm:pb-6 md:pb-10 lg:pb-20 xl:pb-32 pt-0"
               >
                    <h1
                         class="block text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         BUY YOUR GOLD COINS!
                    </h1>
                    <p
                         class="text-white font-helveticaLight tracking-wide mt-5 md:mt-8 mb-4 md:mb-8 text-center font-normal text-base sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                    >
                         Select the amount of gold coins you would like to
                         purchase <br class="hidden lg:block" />
                         or checkout one of our amazing gold coin packages with
                         extra value.
                    </p>
                    <div
                         class="bg-moozi-2 w-full lg:w-8/12 rounded-xl mt-10 mb-5"
                    >
                         <div
                              class="flex flex-row border-b-2 border-solid border-moozi-4 w-full"
                         >
                              <div
                                   class="slider-wrapper w-full lg:w-8/12 lg:border-r-2 border-solid border-moozi-4 p-5 lg:p-8"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-left font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Select amount
                                   </p>
                                   <div
                                        class="flex flex-row justify-between item-center relative z-10 mb-5"
                                   >
                                        <span
                                             @click="(e) => minusAmount(e)"
                                             @mousedown="
                                                  (e) => onHold(e, 'minus')
                                             "
                                             @mouseup="stopIncrement()"
                                             @mouseleave="stopIncrement()"
                                             @touchstart.prevent="
                                                  (e) => onHold(e, 'minus')
                                             "
                                             @touchend.prevent="stopIncrement()"
                                             @touchcancel.prevent="
                                                  stopIncrement()
                                             "
                                             class="icon-minus-box text-2xl"
                                             :class="
                                                  sliderVal > 5
                                                       ? 'cursor-pointer'
                                                       : 'opacity-50 cursor-not-allowed'
                                             "
                                        ></span>
                                        <span
                                             @click="(e) => addAmount(e)"
                                             @mousedown="
                                                  (e) => onHold(e, 'add')
                                             "
                                             @mouseup="stopIncrement()"
                                             @mouseleave="stopIncrement()"
                                             @touchstart.prevent="
                                                  (e) => onHold(e, 'add')
                                             "
                                             @touchend.prevent="stopIncrement()"
                                             @touchcancel.prevent="
                                                  stopIncrement()
                                             "
                                             class="icon-add-box text-2xl"
                                             :class="
                                                  sliderVal < 5000
                                                       ? 'cursor-pointer'
                                                       : 'opacity-50 cursor-not-allowed'
                                             "
                                        ></span>
                                   </div>
                                   <div class="w-full mb-4">
                                        <Slider
                                             v-model="sliderVal"
                                             :format="
                                                  (v) =>
                                                       `$${money2(
                                                            Math.round(v)
                                                       )}`
                                             "
                                             size="20px"
                                             :min="5"
                                             :max="5000"
                                             showTooltip="focus"
                                             class="z-10"
                                        />
                                        <div
                                             class="flex flex-row justify-between item-center mt-2"
                                        >
                                             <p
                                                  class="text-white font-helveticaLight tracking-wide text-center font-bold text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  $5
                                             </p>
                                             <p
                                                  class="text-white font-helveticaLight tracking-wide text-center font-bold text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  $5,000
                                             </p>
                                        </div>
                                   </div>
                                   <!--   -->
                                   <!--  -->

                                   <!--
                                        :disabled="false" -->
                              </div>
                              <div
                                   class="lg:w-4/12 hidden lg:flex flex-col items-center justify-center px-5 py-8"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total Coins
                                   </p>
                                   <div class="flex items-center gap-3 mb-1">
                                        <img
                                             class="cursor-pointer -ml-2 w-8 lg:w-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/GC.svg'
                                             "
                                             alt=""
                                        />
                                        <span
                                             class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-xs sm:text-sm md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl"
                                        >
                                             {{ money2(sliderVal * 10000) }}
                                        </span>
                                   </div>
                                   <div class="flex items-center gap-3">
                                        <img
                                             class="cursor-pointer -ml-2 w-8 lg:w-7"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/SC.svg'
                                             "
                                             alt=""
                                        />
                                        <span
                                             class="text-tertiary-3 font-roboto font-bold tracking-wide text-center text-xxs sm:text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-2xl"
                                        >
                                             + FREE {{ money2(sliderVal * 1) }}
                                        </span>
                                   </div>
                              </div>
                         </div>
                         <div class="flex flex-row w-full p-0 lg:p-8">
                              <div
                                   class="w-2/4 flex flex-col items-center justify-between lg:hidden border-r border-solid border-primary-5 p-5"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total Coins
                                   </p>
                                   <div
                                        class="flex flex-col items-center justify-center"
                                   >
                                        <div
                                             class="flex items-center gap-3 mb-1"
                                        >
                                             <img
                                                  class="cursor-pointer -ml-2 w-6 md:w-8"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/GC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <span
                                                  class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                             >
                                                  {{
                                                       money2(sliderVal * 10000)
                                                  }}
                                             </span>
                                        </div>
                                        <div class="flex items-center gap-3">
                                             <img
                                                  class="cursor-pointer -ml-2 w-5 md:w-7"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/SC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <span
                                                  class="text-tertiary-3 font-roboto font-bold tracking-wide text-center text-base xs:text-lg sm:text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                             >
                                                  + FREE
                                                  {{ money2(sliderVal * 1) }}
                                             </span>
                                        </div>
                                   </div>
                                   <div></div>
                              </div>
                              <div
                                   class="w-2/4 lg:w-full flex flex-col lg:flex-row justify-between items-center gap-5 lg:gap-0 p-5 lg:p-0"
                              >
                                   <div
                                        class="hidden lg:flex flex-row gap-8 items-center"
                                   >
                                        <p
                                             class="text-white font-helveticaLight tracking-wide text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        >
                                             Total price
                                        </p>
                                        <p
                                             class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-xs sm:text-sm md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl"
                                        >
                                             ${{ money2(sliderVal) }}
                                        </p>
                                   </div>
                                   <p
                                        class="block lg:hidden text-white font-helveticaLight tracking-wide text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total price
                                   </p>
                                   <p
                                        class="block lg:hidden text-secondary-7 font-roboto font-bold tracking-wide text-center text-xl sm:text-2xl md:text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                   >
                                        ${{ money2(sliderVal) }}
                                   </p>
                                   <div
                                        @click="
                                             proceedCheckOutCustom(
                                                  sliderVal * 10000,
                                                  sliderVal * 1,
                                                  sliderVal
                                             )
                                        "
                                        class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-5 lg:px-10 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                   >
                                        <span
                                             class="cursor-pointer font-roboto text-sm lg:text-lg font-bold"
                                        >
                                             Buy Now
                                        </span>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div
                         class="px-2 md:px-4 lg:px-6 xl:px-10 2xl:px-20 mt-5 md:mt-7 lg:mt-12 xl:mt-16 w-full"
                    >
                         <div
                              class="flex flex-col justify-start items-center gap-14 w-full"
                         >
                              <div
                                   v-for="(item, key) in coins?.type"
                                   :key="key"
                                   class="justify-center items-center w-full"
                              >
                                   <p
                                        v-if="goldPackage[item.id]"
                                        class="text-white font-helvetica tracking-wider text-center text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mb-2 uppercase"
                                   >
                                        {{ getCoinTypeName(item.id) }}
                                   </p>
                                   <div
                                        v-if="
                                             generateSubDescription(item) != '-'
                                        "
                                        class="-mt-2 mb-4"
                                   >
                                        <p
                                             class="text-white font-helveticaLight tracking-wide text-center font-normal text-xxs md:text-mid lg:text-xs xl:text-sm"
                                        >
                                             {{ generateSubDescription(item) }}
                                        </p>
                                   </div>
                                   <div
                                        class="flex flex-wrap items-start w-full"
                                        :class="
                                             goldPackage[item.id]?.length > 3
                                                  ? 'justify-center lg:justify-start'
                                                  : 'justify-center'
                                        "
                                   >
                                        <div
                                             v-for="(
                                                  coin, kCoin
                                             ) in goldPackage[item.id]"
                                             :key="kCoin"
                                             class="flex-shrink-0 w-full xs:w-2/4 md:w-1/3 lg:w-1/3 xl:w-1/4 p-1"
                                        >
                                             <div
                                                  class="bg-moozi-2 pb-2 sm:pb-3 md:pb-4 justify-between rounded-xl items-center"
                                             >
                                                  <div
                                                       :style="{
                                                            backgroundColor:
                                                                 item.color
                                                                      ? item.color
                                                                      : '#008134'
                                                       }"
                                                       class="w-full rounded-t-xl text-white font-helvetica tracking-wider text-center text-sm sm:text-lg md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl py-1 uppercase relative"
                                                  >
                                                       <p
                                                            :ref="
                                                                 (el) =>
                                                                      (nameRefs[
                                                                           item.id +
                                                                                '-' +
                                                                                kCoin
                                                                      ] = el)
                                                            "
                                                            class="md:truncate ... px-2 sm:px-3 md:px-4 uppercase"
                                                            @mouseenter="
                                                                 setHoveredCoinName(
                                                                      item.id +
                                                                           '-' +
                                                                           kCoin
                                                                 )
                                                            "
                                                            @mouseleave="
                                                                 setHoveredCoinName(
                                                                      null
                                                                 )
                                                            "
                                                       >
                                                            {{
                                                                 generateName(
                                                                      item,
                                                                      coin
                                                                 )
                                                            }}
                                                       </p>
                                                       <div
                                                            v-if="
                                                                 hoveredCoinName ===
                                                                      item.id +
                                                                           '-' +
                                                                           kCoin &&
                                                                 isNameTruncated(
                                                                      item.id +
                                                                           '-' +
                                                                           kCoin
                                                                 )
                                                            "
                                                            class="absolute z-10 p-2 text-primary-1 rounded-md shadow-lg transition-transform duration-200 w-full text-xs sm:text-xs md:text-sm bg-shade-12"
                                                            style="
                                                                 top: 110%;
                                                                 white-space: normal;
                                                                 --tw-bg-opacity: 0.7;
                                                            "
                                                       >
                                                            {{
                                                                 generateName(
                                                                      item,
                                                                      coin
                                                                 )
                                                            }}
                                                       </div>
                                                  </div>
                                                  <div
                                                       class="px-2 sm:px-3 md:px-4"
                                                  >
                                                       <div
                                                            class="flex flex-col items-center mt-1"
                                                       >
                                                            <img
                                                                 :src="'/assets/img/shop/package-coin.png'"
                                                                 alt=""
                                                                 class="lg:w-32 xl:w-40 2xl:w-48 h-auto"
                                                            />
                                                            <p
                                                                 class="uppercase text-secondary-7 font-helvetica tracking-wide text-center text-2xl xl:text-3xl 2xl:text-4xl leading-lg xl:leading-3xl 2xl:leading-4xl"
                                                            >
                                                                 {{
                                                                      money(
                                                                           getSum(
                                                                                coin.gold_coins,
                                                                                coin.gold_coins_free
                                                                           )
                                                                      )
                                                                 }}
                                                                 GC
                                                            </p>
                                                            <div
                                                                 class="flex items-center gap-x-1"
                                                                 :class="
                                                                      getSum(
                                                                           coin.silver_coins,
                                                                           coin.silver_coins_free
                                                                      ) == 0
                                                                           ? 'invisible'
                                                                           : ''
                                                                 "
                                                            >
                                                                 <span
                                                                      class="text-moozi-11 font-helvetica tracking-wider text-center text-lg xl:text-xl 2xl:text-2xl leading-none mb-1"
                                                                      >+</span
                                                                 >
                                                                 <p
                                                                      class="text-moozi-11 font-helvetica tracking-wider text-center text-lg xl:text-xl 2xl:text-2xl"
                                                                 >
                                                                      FREE
                                                                      {{
                                                                           money(
                                                                                getSum(
                                                                                     coin.silver_coins,
                                                                                     coin.silver_coins_free
                                                                                )
                                                                           )
                                                                      }}
                                                                      SC
                                                                 </p>
                                                            </div>
                                                            <hr
                                                                 class="h-px my-2 bg-moozi-4 border-0 rounded-full w-full mt-5"
                                                            />
                                                            <div
                                                                 class="mt-3 relative w-full"
                                                            >
                                                                 <p
                                                                      :ref="
                                                                           (
                                                                                el
                                                                           ) =>
                                                                                (descriptionRefs[
                                                                                     item.id +
                                                                                          '-' +
                                                                                          kCoin
                                                                                ] =
                                                                                     el)
                                                                      "
                                                                      class="text-white font-helveticaLight tracking-wide text-center font-normal text-xs md:text-xs lg:text-sm xl:text-sm md:truncate ..."
                                                                      @mouseenter="
                                                                           setHoveredCoinDescription(
                                                                                item.id +
                                                                                     '-' +
                                                                                     kCoin
                                                                           )
                                                                      "
                                                                      @mouseleave="
                                                                           setHoveredCoinDescription(
                                                                                null
                                                                           )
                                                                      "
                                                                 >
                                                                      {{
                                                                           generateDescription(
                                                                                item,
                                                                                coin
                                                                           )
                                                                      }}
                                                                 </p>

                                                                 <div
                                                                      v-if="
                                                                           hoveredCoinDescription ===
                                                                                item.id +
                                                                                     '-' +
                                                                                     kCoin &&
                                                                           isDescriptionTruncated(
                                                                                item.id +
                                                                                     '-' +
                                                                                     kCoin
                                                                           )
                                                                      "
                                                                      class="absolute z-10 p-2 text-primary-1 rounded-md shadow-lg transition-transform duration-200 w-full text-xs sm:text-xs md:text-sm bg-shade-12"
                                                                      style="
                                                                           top: 110%;
                                                                           white-space: normal;
                                                                           --tw-bg-opacity: 0.7;
                                                                      "
                                                                 >
                                                                      {{
                                                                           generateDescription(
                                                                                item,
                                                                                coin
                                                                           )
                                                                      }}
                                                                 </div>
                                                            </div>
                                                            <div
                                                                 class=""
                                                                 :class="
                                                                      getExpirationDisplay(
                                                                           coin.start_date,
                                                                           coin.end_date
                                                                      ) == '-'
                                                                           ? 'invisible'
                                                                           : 'mt-1 mb-3'
                                                                 "
                                                            >
                                                                 <p
                                                                      class="text-moozi-3 font-helveticaLight tracking-wide text-center font-medium text-xs md:text-mid lg:text-xs xl:text-sm"
                                                                 >
                                                                      {{
                                                                           getExpirationDisplay(
                                                                                coin.start_date,
                                                                                coin.end_date
                                                                           )
                                                                      }}
                                                                 </p>
                                                            </div>
                                                       </div>
                                                       <div class="relative">
                                                            <div
                                                                 class="flex flex-row justify-start items-center"
                                                            >
                                                                 <!-- <div
                                                                      v-if="coin.discount != 0"
                                                                      class="absolute -left-10"
                                                                 >
                                                                      <img
                                                                           :src="
                                                                                baseUrl.img +
                                                                                '/assets/img/shop/badge.png'
                                                                           "
                                                                           alt=""
                                                                           class="lg:w-18 xl:w-20 2xl:w-24 lg:h-18 xl:h-20 2xl:h-24"
                                                                      />
                                                                      <div
                                                                           class="absolute top-0 w-full h-full flex justify-center items-center text-white font-roboto font-bold"
                                                                      >
                                                                           -{{
                                                                                coin.discount
                                                                           }}%
                                                                      </div>
                                                                 </div> -->
                                                                 <div
                                                                      @click="
                                                                           (
                                                                                isUser
                                                                                     ? (coin.type ==
                                                                                            1 &&
                                                                                            (coin.has_bought ==
                                                                                                 1 ||
                                                                                                 (coin.limit !=
                                                                                                      null &&
                                                                                                      coin.buy_count >=
                                                                                                           coin.limit) ||
                                                                                                 today() <
                                                                                                      coin.start_date ||
                                                                                                 today() >=
                                                                                                      coin.end_date)) ||
                                                                                       (item.restricted ==
                                                                                            0 &&
                                                                                            ((coin.limit !=
                                                                                                 null &&
                                                                                                 coin.buy_count >=
                                                                                                      coin.limit) ||
                                                                                                 today() <
                                                                                                      coin.start_date ||
                                                                                                 today() >=
                                                                                                      coin.end_date)) ||
                                                                                       (item.restricted >=
                                                                                            1 &&
                                                                                            (coin.chosen_count >=
                                                                                                 item.restricted ||
                                                                                                 (coin.limit !=
                                                                                                      null &&
                                                                                                      coin.buy_count >=
                                                                                                           coin.limit) ||
                                                                                                 today() <
                                                                                                      coin.start_date ||
                                                                                                 today() >=
                                                                                                      coin.end_date))
                                                                                     : today() <
                                                                                            coin.start_date ||
                                                                                       today() >=
                                                                                            coin.end_date
                                                                           )
                                                                                ? null
                                                                                : proceedCheckOutPackage(
                                                                                       coin[
                                                                                            'id'
                                                                                       ],
                                                                                       item.name,
                                                                                       getSum(
                                                                                            coin.gold_coins,
                                                                                            coin.gold_coins_free
                                                                                       ),
                                                                                       getSum(
                                                                                            coin.silver_coins,
                                                                                            coin.silver_coins_free
                                                                                       ),
                                                                                       getPercentage(
                                                                                            // coin.discount,
                                                                                            coin.net_amount
                                                                                       )
                                                                                  )
                                                                      "
                                                                      class="flex items-center justify-center w-full rounded-full lg:px-4 xl:px-8 2xl:px-10 font-roboto tracking-tight text-center text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl h-10 sm:h-11 font-semibold"
                                                                      :class="
                                                                           isUser
                                                                                ? coin.type ==
                                                                                       1 &&
                                                                                  (coin.has_bought ==
                                                                                       1 ||
                                                                                       (coin.limit !=
                                                                                            null &&
                                                                                            coin.buy_count >=
                                                                                                 coin.limit) ||
                                                                                       today() <
                                                                                            coin.start_date ||
                                                                                       today() >=
                                                                                            coin.end_date)
                                                                                     ? 'bg-shade-12 text-shade-8 cursor-not-allowed'
                                                                                     : (item.restricted ==
                                                                                              0 &&
                                                                                              ((coin.limit !=
                                                                                                   null &&
                                                                                                   coin.buy_count >=
                                                                                                        coin.limit) ||
                                                                                                   today() <
                                                                                                        coin.start_date ||
                                                                                                   today() >=
                                                                                                        coin.end_date)) ||
                                                                                         (item.restricted >=
                                                                                              1 &&
                                                                                              (coin.chosen_count >=
                                                                                                   item.restricted ||
                                                                                                   (coin.limit !=
                                                                                                        null &&
                                                                                                        coin.buy_count >=
                                                                                                             coin.limit) ||
                                                                                                   today() <
                                                                                                        coin.start_date ||
                                                                                                   today() >=
                                                                                                        coin.end_date))
                                                                                       ? 'bg-shade-12 text-shade-8 cursor-not-allowed'
                                                                                       : 'bg-secondary-3 text-primary-1 cursor-pointer'
                                                                                : today() <
                                                                                         coin.start_date ||
                                                                                    today() >=
                                                                                         coin.end_date
                                                                                  ? 'bg-shade-12 text-shade-8 cursor-not-allowed'
                                                                                  : 'bg-secondary-3 text-primary-1 cursor-pointer'
                                                                      "
                                                                 >
                                                                      <!-- <span
                                                                           v-if="
                                                                                coin.discount !=
                                                                                0
                                                                           "
                                                                           :class="
                                                                                coin.discount !=
                                                                                0
                                                                                     ? 'ml-4'
                                                                                     : ''
                                                                           "
                                                                           class="text-secondary-5 line-through mr-2"
                                                                           >${{
                                                                                money(
                                                                                     coin.net_amount
                                                                                )
                                                                           }}</span
                                                                      > -->
                                                                      <span
                                                                           >${{
                                                                                money(
                                                                                     getPercentage(
                                                                                          // coin.discount,
                                                                                          coin.net_amount
                                                                                     )
                                                                                )
                                                                           }}</span
                                                                      >
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="isSelfExclusion != null"
                    class="min-h-screen -mt-16 flex justify-center items-center"
               >
                    <SelfExclusionAlert />
               </div>
          </div>
     </div>
     <CheckoutPayment
          v-if="isUser"
          :wp_response="wp_response"
          :payframeClientLoaded="payframeClientLoaded"
          :confirmationFunc="confirmationFunc"
          :loading="loading"
          :loadingCheckout="loadingCheckout"
          :setLoadingCheckout="setLoadingCheckout"
          :initWorldPay="initWorldPay"
          :setConfirmationFunc="setConfirmationFunc"
     />
     <BankWallet v-if="isUser" />
     <UpdateWallet v-if="isUser" />
     <RemoveWallet v-if="isUser" />
</template>
<script>
import { computed, onMounted, watch } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { coinsStore } from "store/coins";
import { ref } from "vue";
import { useScroll } from "composables/use-scroll.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import { baseUrl, configureWorldPay } from "config/services.js";
import CheckoutPayment from "components/modals/checkoutPayment/index.vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import BankWallet from "components/modals/bankWallet.vue";
import UpdateWallet from "components/modals/updateWallet.vue";
import RemoveWallet from "components/modals/closeWallet.vue";
import { useKYC } from "composables/use-kyc.js";
import { cardStore } from "store/cards.js";
import { authStore } from "store/auth.js";
import { useRouter } from "vue-router";
import { useGTag } from "composables/use-gtag.js";
import { eprotectUrl } from "config/services.js";
import { toWords } from "number-to-words";
import { differenceInHours } from "date-fns";
import { useToaster } from "composables/use-toaster.js";

export default {
     components: {
          InertiaHead,
          SelfExclusionAlert,
          Slider,
          CheckoutPayment,
          BankWallet,
          UpdateWallet,
          RemoveWallet
     },
     setup() {
          const { open_kyc_get_coins } = useKYC();
          const { gTagViewItemList, gTagSelectItemList, gTagBeginCheckout } =
               useGTag();

          const router = useRouter();
          const { errorToast } = useToaster();

          const {
               today,
               money,
               money2,
               formattedDateMDY,
               formatDistanceToNow
          } = useFormatter();
          const { scrollTop } = useScroll();

          const goldPackage = ref(null);
          const nameRefs = ref([]);
          const descriptionRefs = ref([]);

          const useUtilitiesStore = utilitiesStore();
          const useCoinsStore = coinsStore();
          const useCardStore = cardStore();
          const useAuthStore = authStore();

          const sliderVal = ref(5);

          const interval = ref(null);

          const loading = ref(false);

          const wp_response = ref(null);
          const payframeClientLoaded = ref(null);
          const confirmationFunc = ref(false);
          const loadingCheckout = ref(false);

          const hoveredCoinName = ref(null);
          const hoveredCoinDescription = ref(null);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const worldpayStatus = computed(() => {
               if ([true, false].includes(useCoinsStore.worldpayStatus)) {
                    const status =
                         useCoinsStore.worldpayStatus == true ? "prod" : "dev";
                    initCardForm(status);
                    return status;
               } else null;
          });

          const coins = computed(() => {
               if (useCoinsStore.coins?.coins) {
                    const group = _.groupBy(
                         useCoinsStore.coins?.coins,
                         function (num) {
                              return Math.floor(num.type);
                         }
                    );

                    goldPackage.value = group;
                    setViewItemList(
                         useCoinsStore.coins?.coins,
                         useCoinsStore.coins?.coins
                    );
               }
               return useCoinsStore.coins || null;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          //getPercentage backup with discount
          // const getPercentage = (discount, amount) => {
          //      return amount - (discount / 100) * amount;
          // };

          const numberInWords = (val) => {
               if (val) {
                    return toWords(val);
               }
               return "";
          };

          const getPercentage = (amount) => {
               return amount;
          };

          const getSum = (coins, free_coins) => {
               return parseFloat(coins) + parseFloat(free_coins);
          };

          const minusAmount = (e) => {
               e.stopPropagation();
               e.preventDefault();
               if (sliderVal.value > 5) {
                    sliderVal.value = sliderVal.value - 1;
               }
          };

          const addAmount = (e) => {
               e.stopPropagation();
               e.preventDefault();
               if (sliderVal.value < 5000) {
                    sliderVal.value = sliderVal.value + 1;
               }
          };

          const onHold = (e, type) => {
               if (type == "add") {
                    interval.value = setInterval(() => {
                         addAmount(e);
                    }, 100);
                    return;
               }

               interval.value = setInterval(() => {
                    minusAmount(e);
               }, 100);
          };

          const stopIncrement = () => {
               clearInterval(interval.value);
          };

          const onChangeSlider = () => {
               // console.log(value);
          };

          const openPaymentCheckout = (coin) => {
               const gtagSelected = setSelectItemList(coin);
               eventBus.emit("open:payment_checkout", {
                    coin: coin,
                    gtagSelected: gtagSelected
               });
          };

          const closePaymentCheckout = () => {
               eventBus.emit("close:payment_checkout", null);
          };

          const proceedCheckOutPackage = (id, type, gc, sc, total, key) => {
               if (isUser.value == null) {
                    useUtilitiesStore.setMenuGuest(false);
                    router.push({ path: "/signin", force: true });
                    return;
               }

               if (open_kyc_get_coins() == true) {
                    return;
               }

               const coin = {
                    coin_id: id,
                    action: "package",
                    type: type,
                    gc: gc,
                    sc: sc,
                    total: total,
                    key: key
               };

               openPaymentCheckout(coin);
          };

          const proceedCheckOutCustom = (gc, sc, total) => {
               if (isUser.value == null) {
                    useUtilitiesStore.setMenuGuest(false);
                    router.push({ path: "/signin", force: true });
                    return;
               }

               if (open_kyc_get_coins() == true) {
                    return;
               }

               const coin = {
                    action: "custom",
                    gc: gc,
                    sc: sc,
                    total: total
               };

               openPaymentCheckout(coin);
          };

          const setViewItemList = (coins, packageType) => {
               const map = _.map(coins, function (params, key) {
                    const gc = getSum(
                         params["gold_coins"],
                         params["gold_coins_free"]
                    );

                    const sc = getSum(
                         params["silver_coins"],
                         params["silver_coins_free"]
                    );

                    const type = _.find(packageType, function (data) {
                         return data["value"] == params["type"];
                    });

                    return {
                         item_id: params["id"],
                         item_name:
                              type?.label +
                              " " +
                              gc +
                              " GC +FREE " +
                              sc +
                              " SC",
                         index:
                              type?.label.replaceAll(" ", "-").toLowerCase() +
                              key,
                         item_category: type?.label,
                         item_variant: gc + " GC +FREE " + sc + " SC",
                         price: params["net_amount"]
                    };
               });

               gTagViewItemList(map);
          };

          const setSelectItemList = (coin) => {
               let data = null;
               if (coin.action == "custom") {
                    data = {
                         item_id: "custom_id",
                         item_name:
                              "Custom Package" +
                              " " +
                              money(coin.gc) +
                              " GC +FREE " +
                              money(coin.sc) +
                              " SC",
                         index: "custom-0",
                         item_category: "custom_package",
                         item_variant:
                              money(coin.gc) +
                              " GC +FREE " +
                              money(coin.sc) +
                              " SC",
                         price: coin.total
                    };
               } else {
                    data = {
                         item_id: coin.coin_id,
                         item_name:
                              coin.type +
                              " " +
                              money(coin.gc) +
                              " GC +FREE " +
                              money(coin.sc) +
                              " SC",
                         index:
                              coin.type.replaceAll(" ", "-").toLowerCase() +
                              "-" +
                              coin.key,
                         item_category: coin.type,
                         item_variant:
                              money(coin.gc) +
                              " GC +FREE " +
                              money(coin.sc) +
                              " SC",
                         price: coin.total
                    };
               }

               gTagSelectItemList(data);
               gTagBeginCheckout(data.price, data);

               return data;
          };

          const getCoinTypeName = (coin_type_id) => {
               if (coins.value?.type.length && coin_type_id) {
                    const firstMatch = _.find(coins.value.type, {
                         id: coin_type_id
                    });

                    return firstMatch?.name;
               }
          };

          const setHoveredCoinName = (val) => {
               hoveredCoinName.value = val;
          };

          const setHoveredCoinDescription = (val) => {
               hoveredCoinDescription.value = val;
          };

          const getExpirationDisplay = (start_date, end_date) => {
               const dateToday = new Date();
               let endDate = end_date != null ? new Date(end_date) : null;
               let startDate = start_date != null ? new Date(start_date) : null;

               if (endDate != null && startDate != null) {
                    if (dateToday >= startDate && dateToday <= endDate) {
                         if (differenceInHours(endDate, dateToday) <= 24) {
                              return (
                                   "Expires in " +
                                   formatDistanceToNow(endDate, {
                                        addSuffix: true
                                   }) +
                                   "."
                              );
                         } else {
                              return (
                                   "Available from " +
                                   formattedDateMDY(start_date) +
                                   " ~" +
                                   formattedDateMDY(end_date)
                              );
                         }
                    } else {
                         if (dateToday < startDate) {
                              return (
                                   "Available on " +
                                   formattedDateMDY(start_date) +
                                   "."
                              );
                         } else {
                              return (
                                   "Expired " +
                                   formatDistanceToNow(endDate, {
                                        addSuffix: true
                                   }) +
                                   "."
                              );
                         }
                    }
               } else if (startDate != null) {
                    if (dateToday < startDate) {
                         return (
                              "Starts " +
                              formatDistanceToNow(startDate, {
                                   addSuffix: true
                              }) +
                              "."
                         );
                    } else {
                         return "-";
                    }
               } else if (endDate != null) {
                    if (dateToday <= endDate) {
                         if (differenceInHours(endDate, dateToday) <= 24) {
                              return (
                                   "Expires " +
                                   formatDistanceToNow(endDate, {
                                        addSuffix: true
                                   }) +
                                   "."
                              );
                         } else {
                              return "Until " + formattedDateMDY(end_date);
                         }
                    } else {
                         return (
                              "Expired " +
                              formatDistanceToNow(endDate, {
                                   addSuffix: true
                              }) +
                              "."
                         );
                    }
               } else {
                    return "-";
               }
          };

          const isNameTruncated = (index) => {
               const element = nameRefs.value[index];
               if (element) {
                    return element.scrollWidth > element.clientWidth;
               }
               return false;
          };

          const isDescriptionTruncated = (index) => {
               const element = descriptionRefs.value[index];
               if (element) {
                    return element.scrollWidth > element.clientWidth;
               }
               return false;
          };

          const generateName = (item, coin) => {
               return coin.name
                    ? coin.name
                    : item.id == 1
                      ? "ONE-OFF OFFER"
                      : item.restricted >= 1
                        ? "CHOOSE " +
                          item.restricted +
                          " FROM " +
                          goldPackage.value[item.id]?.length
                        : "$" +
                          money2(
                               getPercentage(
                                    // coin.discount,
                                    coin.net_amount
                               )
                          ) +
                          " PACKAGE";
          };

          const generateDescription = (item, coin) => {
               if (item.id == 1) {
                    const limit =
                         coin.limit == 1
                              ? "once."
                              : coin.limit == 2
                                ? "twice."
                                : coin.limit + " times.";

                    return "This package can be purchase only " + limit;
               } else if (coin.description != null) {
                    return coin.description;
               } else if (item.restricted >= 1) {
                    return (
                         "Choose " +
                         item.restricted +
                         " from " +
                         numberInWords(goldPackage.value[item.id]?.length) +
                         " packages."
                    );
               } else {
                    return "Buy bundles, save more.";
               }
          };

          const generateSubDescription = (item) => {
               if (item.restricted >= 1) {
                    return (
                         "You can choose " +
                         item.restricted +
                         " from this group"
                    );
               } else {
                    return "-";
               }
          };

          const inputsEmptyCallback = (res) => {
               var isEmpty = res.allInputsEmpty;
               if (isEmpty) {
                    errorToast("Card input fields empty", "top-right");
                    // errorCard.value = "Card input fields empty";
                    return true;
               } else {
                    return false;
               }
          };

          const payframeClientCallback = async (response) => {
               // console.log(response);
               if (response.timeout) {
                    var elapsedTime = new Date().getTime() - startTime.value;
                    // document.getElementById("timeoutMessage").value =
                    //   "Timed out after " + elapsedTime + "ms";
                    errorToast(
                         "Timed out after " + elapsedTime + "ms",
                         "top-right"
                    );
                    setLoadingCheckout(false);
               } else {
                    if (response.response === "870") {
                         if (response) {
                              wp_response.value = response;
                              confirmationFunc.value = true;
                              setLoadingCheckout(false);
                         }
                    } else if (
                         response.response === "871" ||
                         response.response === "872" ||
                         response.response === "873" ||
                         response.response === "874" ||
                         response.response === "876"
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );
                         setLoadingCheckout(false);
                    } else if (
                         response.response === "881" ||
                         response.response === "882" ||
                         response.response === 883
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );
                         setLoadingCheckout(false);
                    } else if (response.response === "884") {
                         document.getElementById("submitButton").disabled =
                              true;
                         setLoadingCheckout(false);
                    } else {
                         errorToast(
                              "We are experiencing technical difficulties. Please try again or contact us to complete your order",
                              "top-right"
                         );
                         setLoadingCheckout(false);
                    }
               }
          };

          const initWorldPay = async () => {
               try {
                    var payframeClient = await new EprotectIframeClient(
                         configureWorldPay(
                              eprotectUrl[worldpayStatus.value],
                              inputsEmptyCallback,
                              payframeClientCallback
                         )
                    );

                    payframeClientLoaded.value = payframeClient;
               } catch (error) {
                    initCardForm();
                    console.warn(error);
               }
          };

          const setLoadingCheckout = (data) => {
               loadingCheckout.value = data;
          };

          const setConfirmationFunc = (data) => {
               confirmationFunc.value = data;
          };

          onMounted(async () => {
               scrollTop();

               await useCoinsStore.getCoins();
               if (isUser.value) {
                    await useCardStore.getBankStatus();
                    await useCoinsStore.getWorldPay();

                    if (worldpayStatus.value != null) {
                         initCardForm(worldpayStatus.value);
                    }
               }
               await useUtilitiesStore.getState();
          });

          const initCardForm = (value) => {
               loading.value = true;

               const isExist1 = document.querySelector(
                    "#eprotect-jquery-import"
               );

               const isExist2 = document.querySelector("#eprotect-import");

               if (isExist1 == null && isExist2 == null) {
                    var script1 = document.createElement("script");
                    script1.id = "eprotect-jquery-import";
                    script1.src = eprotectUrl[value]["eprotect-jquery-import"];
                    document.body.appendChild(script1);

                    var script2 = document.createElement("script");
                    script2.id = "eprotect-import";
                    // https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js
                    script2.src = eprotectUrl[value]["eprotect-import"];
                    document.body.appendChild(script2);
               }

               let interval = setInterval(() => {
                    if (typeof EprotectIframeClient == "function") {
                         initWorldPay();
                         loading.value = false;
                         clearInterval(interval);
                         console.log("loading false");
                    }
               }, 1000);
          };

          watch(coins, (newVal) => {
               if (newVal) {
                    if (newVal?.coins) {
                         const group = _.groupBy(newVal?.coins, function (num) {
                              return Math.floor(num.type);
                         });

                         goldPackage.value = group;
                    }
               }
          });

          watch(isUser, async (newVal) => {
               if (newVal) {
                    await useCardStore.getBankStatus();
                    await useUtilitiesStore.getState();
               }
          });

          watch(worldpayStatus, async (newVal) => {
               console.log(newVal);

               if (newVal) {
                    initCardForm(newVal);
               }
          });

          return {
               coins,
               money,
               money2,
               goldPackage,
               getPercentage,
               getSum,
               sliderVal,
               minusAmount,
               addAmount,
               onHold,
               stopIncrement,
               onChangeSlider,
               baseUrl,
               openPaymentCheckout,
               closePaymentCheckout,
               proceedCheckOutPackage,
               proceedCheckOutCustom,
               isSelfExclusion,
               isUser,
               wp_response,
               payframeClientLoaded,
               today,
               getCoinTypeName,
               numberInWords,
               hoveredCoinName,
               setHoveredCoinName,
               getExpirationDisplay,
               isNameTruncated,
               nameRefs,
               isDescriptionTruncated,
               descriptionRefs,
               generateName,
               generateDescription,
               generateSubDescription,
               setHoveredCoinDescription,
               hoveredCoinDescription,
               confirmationFunc,
               loadingCheckout,
               setLoadingCheckout,
               loading,
               initWorldPay,
               setConfirmationFunc
          };
     }
};
</script>
