<template>
     <div v-if="data?.data?.length != 0">
          <div class="flex flex-col -mt-2 mb-5 md:mb-10">
               <div class="flex flex-row items-center justify-between">
                    <div
                         v-if="!loading && data?.data"
                         class="flex flex-row items-center gap-2 text-white lobby-category-clickable hover:text-moozi-3 cursor-pointer duration-300 ease-in-out"
                         @click="onSetActive(game_type)"
                    >
                         <slot name="icon"></slot>
                         <p
                              class="font-roboto font-bold text-sm lg:text-lg capitalize"
                         >
                              {{ title }}
                         </p>
                    </div>
                    <Skeletor
                         v-else
                         as="div"
                         class="!w-32 lg:!w-44 !h-6 lg:!h-7 !rounded-md mx-1.5"
                    />
                    <div
                         v-if="!loading"
                         class="flex flex-row gap-2"
                    >
                         <div
                              @click="onPrev"
                              :class="
                                   currentSlide > 0
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="23"
                                   height="23"
                                   viewBox="0 0 23 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide > 0
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z"
                                   />
                              </svg>
                         </div>
                         <div
                              @click="onNext"
                              :class="
                                   currentSlide < nextLimit
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="22"
                                   height="23"
                                   viewBox="0 0 22 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide < nextLimit
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z"
                                   />
                              </svg>
                         </div>
                    </div>
               </div>
               <div class="mt-2">
                    <Carousel
                         v-if="!loading && data?.data"
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="true"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItemsWinner"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="item in data?.data"
                              :key="item.id"
                              :game-id="item.id"
                         >
                              <div
                                   @click="() => onRedirect(item)"
                                   class="game-card bg-moozi-2 rounded-md w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             :alt="item.game_pic"
                                             class="broken-image absolute top-0 left-0 rounded-t-md w-full h-full game-thumbnails invisible"
                                        />
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                        >
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic != null &&
                                                       !loading
                                                  "
                                                  :src="item.game_pic"
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic_mobile !=
                                                            null && !loading
                                                  "
                                                  :src="item.game_pic_mobile"
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="item.game_pic"
                                                  class="thumbnail-image rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                             />
                                        </div>
                                   </div>
                                   <div
                                        class="flex flex-col items-start px-1 py-1 md:px-2 md:py-2 text-left text-white text-xxs sm:text-xs lg:text-base font-bold w-full space-y-1 cursor-pointer"
                                   >
                                        <div
                                             v-if="item.currency != 'SCC'"
                                             class="rounded-full coin-wrapper cursor-pointer w-full"
                                        >
                                             <div
                                                  class="flex items-center justify-start tracking-wide"
                                             >
                                                  <img
                                                       class="cursor-pointer w-2 xs:w-3 sm:w-4 lg:w-5"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="pl-1 truncate ... !text-white leading-tight"
                                                  >
                                                       {{ money3(item.amount) }}
                                                  </p>
                                             </div>
                                        </div>
                                        <div
                                             v-else
                                             class="rounded-full coin-wrapper cursor-pointer w-full"
                                        >
                                             <div
                                                  class="flex items-center justify-start tracking-wide"
                                             >
                                                  <img
                                                       class="cursor-pointer w-2 xs:w-3 sm:w-4 lg:w-5"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="pl-1 truncate ... !text-white leading-tight"
                                                  >
                                                       {{ money3(item.amount) }}
                                                  </p>
                                             </div>
                                        </div>
                                        <p
                                             class="!text-moozi-6 text-xxxs sm:text-mid lg:text-xs truncate ... w-full leading-tight font-semibold"
                                        >
                                             {{ item.currentuser }}
                                        </p>
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
                    <Carousel
                         v-else
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItemsWinner"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="(item, key) in 15"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <Skeletor
                                             as="div"
                                             class="!absolute !top-0 !left-0 !rounded-md !w-full !h-full"
                                        />
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
               </div>
          </div>
     </div>
</template>
<script>
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";
import { ref, onMounted, computed, watch } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";
import { Carousel, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {
          Carousel,
          Slide
     },
     props: [
          "data",
          "title",
          "overideOnlick",
          "onNextSlide",
          "pagination",
          "game_type",
          "onSetActive"
     ],
     setup(props) {
          const useGamesStore = gamesStore();

          const router = useRouter();

          const loading = ref(false);

          const currentSlide = ref(0);

          const showItemsWinner = ref({
               0: {
                    itemsToShow: 3
               },
               420: {
                    itemsToShow: 3
               },
               640: {
                    itemsToShow: 3
               },
               768: {
                    itemsToShow: 3
               },
               1024: {
                    itemsToShow: 8
               },
               1280: {
                    itemsToShow: 10
               },
               1536: {
                    itemsToShow: 12
               },
               1800: {
                    itemsToShow: 13
               }
          });

          const nextLimit = ref(0);

          const { money3 } = useFormatter();

          const { onImageError } = useImage();

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const onNext = async () => {
               const current = currentSlide.value;
               console.log(current);
               console.log(nextLimit.value);

               if (current < nextLimit.value) {
                    currentSlide.value = current + 3;
               }
          };

          const onPrev = () => {
               const current = currentSlide.value;
               if (current > 0) {
                    currentSlide.value = current - 3;
               }
          };

          const adjustNextLimit = () => {
               window.addEventListener("resize", () => {
                    currentSlide.value = 0;
                    setNextLimit();
               });
          };

          const setNextLimit = () => {
               const getWidth = window.innerWidth;

               const find = _.filter(
                    Object.keys(showItemsWinner.value),
                    function (params) {
                         return getWidth >= parseInt(params);
                    }
               );

               let size = parseInt(find.pop());
               size = Math.round(showItemsWinner.value[size]["itemsToShow"]);

               nextLimit.value =
                    size >= props.data?.data?.length
                         ? 0
                         : props.data?.data?.length - size;
          };

          const getProvider = (id) => {
               const find = _.find(providerData.value, (params) => {
                    return params["id"] == id;
               });

               return find ? find["name"] : "";
          };

          const onRedirect = (data) => {
               if (props.overideOnlick) {
                    props?.overideOnlick(data.game_name, data.id);
                    return;
               }
               openGamePreview(data, () => {
                    router.push({
                         path:
                              "/games/detail/" +
                              encodeURIComponent(
                                   data.game_name.replaceAll(" ", "-")
                              ) +
                              "-" +
                              data.id
                    });
               });
          };

          const openGamePreview = (data, onPlay) => {
               eventBus.emit("open:gamepreview", {
                    data: data,
                    onPlay: onPlay
               });
          };

          onMounted(async () => {
               setNextLimit();
               adjustNextLimit();
          });

          watch(props.data, (newVal) => {
               if (newVal) {
                    setNextLimit();
               }
               if (newVal) {
                    loading.value = true;
                    setTimeout(() => {
                         loading.value = false;
                    }, 2000);
               }
          });

          return {
               baseUrl,
               onNext,
               onPrev,
               currentSlide,
               nextLimit,
               getProvider,
               loading,
               onRedirect,
               onImageError,
               openGamePreview,
               money3,
               showItemsWinner
          };
     }
};
</script>
