<template>
     <div class="h-full w-full">
          <transition
               enter-from-class="opacity-0"
               enter-active-class="transition ease-in duration-300"
               enter-to-class="opacity-100 scale-100"
          >
               <form
                    :class="isAddNewCard ? '' : 'hidden'"
                    class="flex h-full w-full"
                    @submit.prevent="onSubmit"
                    method="post"
                    id="fCheckout"
                    name="fCheckout"
                    onsubmit="return false;"
               >
                    <div
                         class="transform ease-in-out transition-all duration-300 h-full w-full flex flex-col"
                    >
                         <div class="h-full flex flex-col">
                              <div class="flex items-center justify-between">
                                   <h3
                                        class="font-bold text-moozi-1 text-sm xl:text-base mt-4 mb-4"
                                   >
                                        Credit card details
                                   </h3>
                              </div>
                              <div
                                   v-if="cardError != ''"
                                   class="bg-primal-red rounded-xl p-4 flex flex-row gap-3 items-center"
                              >
                                   <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M12 9V13M12 16V16.01M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z"
                                             stroke="white"
                                             stroke-width="2"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                        />
                                   </svg>
                                   <h3
                                        class="text-sm font-medium text-white w-full"
                                   >
                                        {{ cardError }}
                                   </h3>
                                   <svg
                                        @click="cardError = ''"
                                        class="cursor-pointer"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M13 1L1 13M1 1L13 13"
                                             stroke="white"
                                             stroke-width="2"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                        />
                                   </svg>
                              </div>
                              <div>
                                   <label
                                        for="card_name"
                                        class="block mb-2 text-sm font-bold text-shade-2"
                                   >
                                        Card holder name
                                   </label>
                                   <input
                                        v-model="payment.card_name"
                                        type="text"
                                        name="card_name"
                                        id="card_name"
                                        class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                                        placeholder="Enter your full name"
                                        autocomplete="card_name"
                                        @change="
                                             () => {
                                                  clearFormError('card_name');
                                             }
                                        "
                                        :class="
                                             payment.errors?.errors
                                                  ?.card_name != null
                                                  ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                  : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                                        "
                                   />
                                   <has-error-form
                                        v-model:form="payment"
                                        :field="'card_name'"
                                   ></has-error-form>

                                   <div class="checkout">
                                        <input
                                             type="hidden"
                                             id="bin"
                                             name="bin"
                                             readOnly="true"
                                        />
                                        <input
                                             type="hidden"
                                             id="request$merchantTxnId"
                                             name="request$merchantTxnId"
                                             value="10174000"
                                        />
                                        <input
                                             type="hidden"
                                             id="request$reportGroup"
                                             name="request$reportGroup"
                                             value="Cert30 Merchant Rollup**"
                                        />
                                        <input
                                             type="hidden"
                                             id="request$timeout"
                                             name="request$timeout"
                                             value="5000"
                                        />
                                        <input
                                             type="hidden"
                                             id="request$orderId"
                                             name="request$orderId"
                                             value="order_123"
                                        />
                                        <div
                                             id="payframe"
                                             style="width: 100%"
                                        ></div>
                                   </div>
                              </div>
                         </div>
                         <div>
                              <div
                                   class="flex flex-wrap w-full gap-4 mt-4 sm:mt-6 md:mt-8"
                              >
                                   <div
                                        @click="cancelButton()"
                                        class="flex-1 w-full cursor-pointer text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                   >
                                        Cancel
                                   </div>
                                   <button
                                        id="submitButton"
                                        type="submit"
                                        class="flex-1 w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                   >
                                        Save
                                   </button>
                              </div>
                         </div>
                    </div>
               </form>
          </transition>

          <transition
               enter-from-class="opacity-0"
               enter-active-class="transition ease-in duration-300"
               enter-to-class="opacity-100 scale-100"
          >
               <div
                    :class="!isAddNewCard ? '' : 'hidden'"
                    class="flex h-full"
               >
                    <div class="flex flex-col justify-between w-full">
                         <div class="w-full">
                              <p
                                   class="text-shade-8 text-sm xl:text-base mt-4 mb-4"
                              >
                                   Connect your credit account for easier
                                   payment setup with no expiration and faster
                                   refunds.
                              </p>
                              <p
                                   class="text-sm xl:text-base mb-6 text-moozi-1 font-bold"
                              >
                                   Current Account
                              </p>
                              <div
                                   v-if="cardDetails.length"
                                   class="flex flex-col gap-2"
                              >
                                   <div
                                        v-for="(item, key) in cardDetails"
                                        :key="key"
                                   >
                                        <div
                                             class="flex flex-row items-center w-full gap-2 px-4 py-3 bg-gray-150 border border-shade-10 border-solid rounded-lg"
                                        >
                                             <div class="flex flex-row w-full">
                                                  <span
                                                       class="w-full flex flex-row gap-2 justify-start items-center text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                                  >
                                                       <div>
                                                            <img
                                                                 :class="
                                                                      item
                                                                           .paypage
                                                                           ?.type ==
                                                                      'VI'
                                                                           ? ''
                                                                           : 'hidden'
                                                                 "
                                                                 :src="
                                                                      baseUrl.img +
                                                                      '/assets/img/card/visa-rounded.png'
                                                                 "
                                                                 class="h-6"
                                                            />
                                                            <img
                                                                 :class="
                                                                      item
                                                                           .paypage
                                                                           ?.type ==
                                                                      'MC'
                                                                           ? ''
                                                                           : 'hidden'
                                                                 "
                                                                 :src="
                                                                      baseUrl.img +
                                                                      '/assets/img/card/master-rounded.png'
                                                                 "
                                                                 class="h-6"
                                                            />
                                                            <img
                                                                 :class="
                                                                      item
                                                                           .paypage
                                                                           ?.type ==
                                                                      'DI'
                                                                           ? ''
                                                                           : 'hidden'
                                                                 "
                                                                 :src="
                                                                      baseUrl.img +
                                                                      '/assets/img/card/discover-rounded.png'
                                                                 "
                                                                 class="h-6"
                                                            />
                                                       </div>
                                                       <div
                                                            class="flex flex-col"
                                                       >
                                                            <span
                                                                 class="capitalize flex flex-row items-center gap-1 font-bold text-moozi-1"
                                                            >
                                                                 {{
                                                                      item
                                                                           .paypage
                                                                           ?.card_name
                                                                 }}
                                                                 <span
                                                                      class="uppercase font-normal rounded-full md:px-2 text-mid py-0 w-2 md:w-auto h-2 md:h-max text-transparent md:text-white"
                                                                      :class="
                                                                           item
                                                                                .paypage
                                                                                ?.isRestricted ==
                                                                           true
                                                                                ? 'bg-secondary-5'
                                                                                : item.status ==
                                                                                    1
                                                                                  ? 'bg-shade-14'
                                                                                  : 'bg-shade-15'
                                                                      "
                                                                 >
                                                                      {{
                                                                           item
                                                                                .paypage
                                                                                ?.isRestricted ==
                                                                           true
                                                                                ? "Not Supported"
                                                                                : item.status ==
                                                                                    1
                                                                                  ? "Active"
                                                                                  : "Inactive"
                                                                      }}
                                                                 </span>
                                                            </span>
                                                            <span>
                                                                 Account ending
                                                                 in
                                                                 <span
                                                                      class="font-bold"
                                                                 >
                                                                      {{
                                                                           item
                                                                                .paypage
                                                                                ?.lastFour
                                                                      }}
                                                                 </span>
                                                            </span>
                                                       </div>
                                                  </span>
                                             </div>
                                             <div class="flex h-full">
                                                  <span
                                                       @click="
                                                            openDeleteCC(
                                                                 item.id
                                                            )
                                                       "
                                                       class="cursor-pointer"
                                                  >
                                                       <span
                                                            class="text-black underline underline-offset-2 cursor-pointer text-xs lg:text-sm"
                                                       >
                                                            Remove
                                                       </span>
                                                  </span>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div v-else>
                                   <div
                                        class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
                                   >
                                        No payment method. Please add a new
                                        card.
                                   </div>
                              </div>
                         </div>
                         <div>
                              <div
                                   @click="addNewCard()"
                                   class="w-full my-5 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full bg-secondary-3 text-primary-1 text-sm font-extrabold cursor-pointer"
                              >
                                   Add new card
                              </div>
                              <p
                                   class="font-roboto text-shade-8 text-center text-xs lg:text-sm mb-5"
                              >
                                   Moozi accepts the following credit cards and
                                   debit cards:
                              </p>
                              <div
                                   class="font-roboto flex flex-row gap-2 items-center justify-center w-full"
                              >
                                   <div
                                        class="flex flex-row gap-2 items-center"
                                   >
                                        <p
                                             class="text-shade-7 text-xs lg:text-sm"
                                        >
                                             Credit card
                                        </p>
                                        <img
                                             class="h-6 lg:h-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/visa-rounded.png'
                                             "
                                             alt="visa"
                                        />
                                   </div>
                                   <div
                                        class="flex flex-row gap-2 items-center"
                                   >
                                        <p
                                             class="text-shade-7 text-xs lg:text-sm"
                                        >
                                             Or
                                        </p>
                                   </div>
                                   <div
                                        class="flex flex-row gap-2 items-center"
                                   >
                                        <p
                                             class="text-shade-7 text-xs lg:text-sm"
                                        >
                                             Debit card
                                        </p>
                                        <img
                                             class="h-6 lg:h-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/visa-rounded.png'
                                             "
                                             alt="visa"
                                        />
                                        <img
                                             class="h-6 lg:h-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/discover-rounded.png'
                                             "
                                             alt="discover"
                                        />
                                        <img
                                             class="h-6 lg:h-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/master-rounded.png'
                                             "
                                             alt="master"
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </transition>
     </div>
     <creditDeleteConfirmation
          :id="deleteCardID ?? 0"
          :coins="coins ?? {}"
          :selectedCard="{}"
     />
</template>

<script>
import { computed, onMounted, reactive, ref, watch, toRefs } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { useFormatter } from "composables/use-formatter.js";
import { useRoute } from "vue-router";
import { saveCard as saveCardAPI } from "api/payment.js";
import Form from "vform";
import { vMaska } from "maska";
import { useToaster } from "composables/use-toaster.js";
import HasErrorForm from "components/haserror/index.vue";
import { cardStore } from "store/cards";
import creditDeleteConfirmation from "components/modals/creditDeleteConfirmation.vue";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";

export default {
     props: [
          "coins",
          "setLoading",
          "setPaymentSuccessCoins",
          "wp_response",
          "payframeClientLoaded",
          "confirmationFunc",
          "loading",
          "initWorldPay",
          "setConfirmationFunc"
     ],
     components: {
          HasErrorForm,
          creditDeleteConfirmation
          // searchableSelect
     },
     directives: { maska: vMaska },
     setup(props) {
          const { open_kyc_bank } = useKYC();

          const { successToast, errorToast } = useToaster();
          const { money } = useFormatter();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();

          const city = ref([]);
          const zipCode = ref([]);
          const showCVV = ref(false);
          const formDisable = ref(false);

          const { confirmationFunc } = toRefs(props);

          const cardError = ref("");

          const deleteCardID = ref(null);

          const route = useRoute();

          const cardType = ref("");

          const isAddNewCard = ref(false);

          const payment = reactive(
               new Form({
                    card_name: "",
                    billing: {
                         address_1: "",
                         address_2: "",
                         state: "",
                         city: "",
                         zipcode: ""
                    },
                    coin_id: null,
                    payment_category: "CARD"
               })
          );

          const startTime = ref(null);

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const cardDetails = computed(() => {
               return useCardStore.cards ?? [];
          });

          const saveCreditCard = async () => {
               props.setLoading(true);
               var params = {};

               params = {
                    card_name: payment.card_name,
                    billing: payment.billing,
                    payment_category: payment.payment_category,
                    transaction_type: "profile"
               };

               if (props.wp_response?.message != "Success") {
                    props.setConfirmationFunc(false);
                    setTimeout(() => {
                         props.setLoading(false);
                    }, 1000);
                    return;
               } else {
                    params["paypageRegistrationId"] =
                         props.wp_response?.paypageRegistrationId;
                    (params["card_expiration"] =
                         props.wp_response?.expMonth &&
                         props.wp_response?.expYear
                              ? props.wp_response?.expMonth +
                                "/" +
                                props.wp_response?.expYear
                              : ""),
                         (params["card_cvv"] = "");
                    params["type"] = props.wp_response?.type;
                    params["vantivTxnId"] = props.wp_response?.vantivTxnId;
                    params["paypageId"] = props.wp_response?.id;
                    params["firstSix"] = props.wp_response?.firstSix;
                    params["lastFour"] = props.wp_response?.lastFour;
                    params["bin"] = props.wp_response?.bin;
               }

               await saveCardAPI(params)
                    .then((res) => {
                         props.setConfirmationFunc(false);
                         setTimeout(() => {
                              props.setLoading(false);
                         }, 1000);
                         successToast(res.message, "top-right");
                         payment.reset();
                         payment.errors.errors = {};
                         payment.coin_id = route.path.split("/")[2];
                         useCardStore.getCards(true);
                         isAddNewCard.value = false;
                         useUtilitiesStore.enableScroll();
                         props.initWorldPay();
                    })
                    .catch(async ({ response }) => {
                         const { data, status, message } = response;
                         if (status == 403) {
                              await useCardStore.getBankStatus();
                              errorToast(data.message, "top-right");
                         } else if (status == 422) {
                              if (data.message != undefined) {
                                   if (data.message) {
                                        errorToast(data.message, "top-right");
                                        payment.errors.errors = data?.errors;
                                   } else {
                                        errorToast(message, "top-right");
                                   }
                              } else {
                                   errorToast(
                                        data[Object.keys(data)[0][0]],
                                        "top-right"
                                   );
                                   payment.errors.errors = data?.errors;
                              }
                         } else {
                              if (!data.status && data["status"] != undefined) {
                                   errorToast(data.message, "top-right");
                              }
                              // openPaymentFailed();
                         }
                         // closeConfirmationModal();
                         //  errorToast("Invalid Payment", "top-right");
                         props.setConfirmationFunc(false);
                         setTimeout(() => {
                              props.setLoading(false);
                         }, 1000);
                    });
          };

          const onSubmit = async () => {
               props.setLoading(true);
               if (props.payframeClientLoaded.allInputsEmpty()) {
                    props.setConfirmationFunc(false);
                    setTimeout(() => {
                         props.setLoading(false);
                    }, 1000);
                    return false;
               }

               var message = {
                    id: document.getElementById("request$merchantTxnId").value,
                    orderId: document.getElementById("request$orderId").value
               };
               startTime.value = new Date().getTime();
               props.payframeClientLoaded.getPaypageRegistrationId(message);
               return false;
          };

          const clearFormError = (key) => {
               if (typeof payment.errors.errors[key] !== "undefined") {
                    delete payment.errors.errors[key];
               }
          };

          const openDeleteCC = (id) => {
               deleteCardID.value = id;
               eventBus.emit("open:deleteconfirmation");
          };

          const addNewCard = () => {
               if (open_kyc_bank() == true) {
                    return;
               }

               isAddNewCard.value = !isAddNewCard.value;
          };

          const numbersOnly = (evt) => {
               evt = evt ? evt : window.event;
               var charCode = evt.which ? evt.which : evt.keyCode;
               if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
               ) {
                    evt.preventDefault();
               } else {
                    return true;
               }
          };

          const cancelButton = () => {
               props.setConfirmationFunc(false);
               props.initWorldPay();
               payment.reset();
               payment.errors.errors = {};
               isAddNewCard.value = false;
               window.scrollTo({
                    top: 0,
                    behavior: "smooth"
               });
          };

          onMounted(async () => {
               payment.coin_id = route.path.split("/")[2];
               await useCardStore.getCards(true);
          });

          watch(confirmationFunc, async (newVal) => {
               if (newVal == true) {
                    setTimeout(async () => {
                         await saveCreditCard();
                    }, 0);
               }
          });

          return {
               state,
               city,
               zipCode,
               money,
               payment,
               numbersOnly,
               formDisable,
               onSubmit,
               clearFormError,
               openDeleteCC,
               deleteCardID,
               showCVV,
               cardType,
               cardError,
               isAddNewCard,
               addNewCard,
               cancelButton,
               baseUrl,
               cardDetails
          };
     }
};
</script>

<style>
#payframe iframe {
     border: 0;
}
</style>
