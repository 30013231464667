import request from "@/utils/request";

export function getDaily() {
     return request({
          url: "/user/bonus/daily",
          method: "get"
     });
}

export function getWelcome() {
     return request({
          url: "/user/bonus/welcome",
          method: "get"
     });
}

export function claimDaily() {
     return request({
          url: "/user/bonus/daily/claim",
          method: "post"
     });
}

export function claimWelcome() {
     return request({
          url: "/user/bonus/welcome/claim",
          method: "post"
     });
}

export function freeGC() {
     return request({
          url: "/user/bonus/freegcc/claim",
          method: "post"
     });
}

export function getReferralStats() {
     return request({
          url: "/user/bonus/referral/stats",
          method: "get"
     });
}

export function addReferralStats(data) {
     return request({
          url: "/referral/stats",
          method: "get",
          params: data
     });
}
