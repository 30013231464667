<template>
     <InertiaHead title="Account"></InertiaHead>
     <div
          class="moozi-min-h w-full h-full relative flex justify-center bg-moozi-1"
     >
          <div
               v-if="isSelfExclusion == null"
               class="w-full 2xl:max-w-screen-2xl bg-transparent relative"
          >
               <div
                    class="w-full h-full flex flex-col justify-center items-center pb-20 lg:pb-26 pt-0"
               >
                    <h1
                         class="lg:hidden text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         MY ACCOUNT
                    </h1>
                    <div
                         class="bg-moozi-2 h-full rounded-xl flex flex-col flex-shrink xl:flex-row w-full relative mt-5 lg:mt-0"
                    >
                         <div
                              class="w-full xl:w-4/12 2xl:w-3/12 absolute z-50 lg:static xl:z-0"
                         >
                              <div
                                   class="flex flex-col justify-between items-center w-full h-full"
                              >
                                   <div
                                        class="w-full flex flex-col h-full items-center justify-start xl:pl-7 2xl:pl-10 md:pt-0 xl:pt-5 relative"
                                   >
                                        <div
                                             @click="openMenu = !openMenu"
                                             class="h-12 flex items-center justify-center w-full xl:hidden cursor-pointer"
                                        >
                                             <div
                                                  class="w-4 ml-3 xl:hidden"
                                             ></div>
                                             <span
                                                  class="w-full text-sm lg:text-base capitalize text-white font-roboto tracking-wider !mt-0 font-bold text-center"
                                             >
                                                  {{
                                                       activeTab.replaceAll(
                                                            "-",
                                                            " "
                                                       )
                                                  }}
                                             </span>
                                             <svg
                                                  :class="
                                                       openMenu
                                                            ? 'rotate-180'
                                                            : 'rotate-0'
                                                  "
                                                  class="transition-transform duration-300 mr-3"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       fill-rule="evenodd"
                                                       clip-rule="evenodd"
                                                       d="M5.29279 7.29259C5.48031 7.10512 5.73462 6.99981 5.99979 6.99981C6.26495 6.99981 6.51926 7.10512 6.70679 7.29259L9.99979 10.5856L13.2928 7.29259C13.385 7.19708 13.4954 7.1209 13.6174 7.06849C13.7394 7.01608 13.8706 6.9885 14.0034 6.98734C14.1362 6.98619 14.2678 7.01149 14.3907 7.06177C14.5136 7.11205 14.6253 7.18631 14.7192 7.2802C14.8131 7.37409 14.8873 7.48574 14.9376 7.60864C14.9879 7.73154 15.0132 7.86321 15.012 7.99599C15.0109 8.12877 14.9833 8.25999 14.9309 8.382C14.8785 8.504 14.8023 8.61435 14.7068 8.70659L10.7068 12.7066C10.5193 12.8941 10.265 12.9994 9.99979 12.9994C9.73462 12.9994 9.48031 12.8941 9.29279 12.7066L5.29279 8.70659C5.10532 8.51907 5 8.26476 5 7.99959C5 7.73443 5.10532 7.48012 5.29279 7.29259Z"
                                                       fill="white"
                                                  />
                                             </svg>
                                        </div>
                                        <ul
                                             :class="
                                                  openMenu
                                                       ? 'absolute'
                                                       : 'hidden xl:block'
                                             "
                                             class="overflow-x-auto xl:overflow-x-visible mt-14 md:mt-15 lg:mt-14 xl:mt-0 w-full px-2 xl:px-0"
                                        >
                                             <li
                                                  v-for="(items, key) in tabs"
                                                  :key="key"
                                                  :class="{
                                                       'bg-moozi-2 xl:transparent':
                                                            openMenu,
                                                       'rounded-t-md': key == 0,
                                                       'rounded-b-md':
                                                            key + 1 ==
                                                            tabs.length
                                                  }"
                                                  class="font-roboto tracking-wider w-full !mt-0 font-bold text-sm sm:text-base"
                                             >
                                                  <span
                                                       @click="
                                                            setTab(items.type)
                                                       "
                                                       :class="{
                                                            'flex text-secondary-2':
                                                                 activeTab ==
                                                                      items.type &&
                                                                 openMenu,
                                                            'text-white block':
                                                                 activeTab !=
                                                                      items.type &&
                                                                 openMenu,
                                                            'text-secondary-2 md:flex':
                                                                 activeTab ==
                                                                      items.type &&
                                                                 !openMenu,
                                                            'text-white hidden xl:flex':
                                                                 activeTab !=
                                                                      items.type &&
                                                                 !openMenu
                                                       }"
                                                       class="account-details-list flex items-center px-2 py-3 xl:py-3 rounded-lg w-full cursor-pointer justify-between xl:justify-start"
                                                       aria-current="page"
                                                  >
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'account-details'
                                                            "
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            viewBox="0 0 24 25"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 d="M3 20.5C5.33579 18.0226 8.50702 16.5 12 16.5C15.493 16.5 18.6642 18.0226 21 20.5M16.5 8C16.5 10.4853 14.4853 12.5 12 12.5C9.51472 12.5 7.5 10.4853 7.5 8C7.5 5.51472 9.51472 3.5 12 3.5C14.4853 3.5 16.5 5.51472 16.5 8Z"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'payment-details'
                                                            "
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 d="M3 10H21M7 15H7.01M11 15H13M3 8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H18C18.7956 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V16C21 16.7956 20.6839 17.5587 20.1213 18.1213C19.5587 18.6839 18.7956 19 18 19H6C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V8Z"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'coins'
                                                            "
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                       >
                                                            <path
                                                                 stroke-linecap="round"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-linejoin="round"
                                                                 d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'verification'
                                                            "
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            viewBox="0 0 24 25"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 d="M9 12.5L11 14.5L15.5 10M17.9012 5.49851C18.1071 5.99653 18.5024 6.3924 19.0001 6.59907L20.7452 7.32198C21.2433 7.52828 21.639 7.92399 21.8453 8.42206C22.0516 8.92012 22.0516 9.47974 21.8453 9.97781L21.1229 11.7218C20.9165 12.2201 20.9162 12.7803 21.1236 13.2783L21.8447 15.0218C21.9469 15.2685 21.9996 15.5329 21.9996 15.7999C21.9997 16.067 21.9471 16.3314 21.8449 16.5781C21.7427 16.8249 21.5929 17.049 21.4041 17.2378C21.2152 17.4266 20.991 17.5764 20.7443 17.6785L19.0004 18.4009C18.5023 18.6068 18.1065 19.0021 17.8998 19.4998L17.1769 21.245C16.9706 21.7431 16.575 22.1388 16.0769 22.3451C15.5789 22.5514 15.0193 22.5514 14.5212 22.3451L12.7773 21.6227C12.2792 21.4169 11.7198 21.4173 11.2221 21.6239L9.47689 22.3458C8.97912 22.5516 8.42001 22.5514 7.92237 22.3453C7.42473 22.1391 7.02925 21.7439 6.82281 21.2464L6.09972 19.5006C5.8938 19.0026 5.49854 18.6067 5.00085 18.4L3.25566 17.6771C2.75783 17.4709 2.36226 17.0754 2.15588 16.5777C1.94951 16.0799 1.94923 15.5205 2.1551 15.0225L2.87746 13.2786C3.08325 12.7805 3.08283 12.2211 2.8763 11.7233L2.15497 9.97678C2.0527 9.7301 2.00004 9.46568 2 9.19863C1.99996 8.93159 2.05253 8.66715 2.15472 8.42043C2.25691 8.17372 2.40671 7.94955 2.59557 7.76075C2.78442 7.57195 3.00862 7.42222 3.25537 7.3201L4.9993 6.59772C5.49687 6.39197 5.89248 5.9972 6.0993 5.50006L6.82218 3.75481C7.02848 3.25674 7.42418 2.86103 7.92222 2.65473C8.42027 2.44842 8.97987 2.44842 9.47792 2.65473L11.2218 3.37712C11.7199 3.58291 12.2793 3.58249 12.7771 3.37595L14.523 2.65585C15.021 2.44966 15.5804 2.4497 16.0784 2.65597C16.5763 2.86223 16.972 3.25783 17.1783 3.75576L17.9014 5.50153L17.9012 5.49851Z"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'security'
                                                            "
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 d="M9.98633 10C9.72111 10 9.46676 9.89464 9.27922 9.70711C9.09169 9.51957 8.98633 9.26522 8.98633 9C8.98633 8.73478 9.09169 8.48043 9.27922 8.29289C9.46676 8.10536 9.72111 8 9.98633 8C10.2515 8 10.5059 8.10536 10.6934 8.29289C10.881 8.48043 10.9863 8.73478 10.9863 9C10.9863 9.26522 10.881 9.51957 10.6934 9.70711C10.5059 9.89464 10.2515 10 9.98633 10ZM9.98633 10V12.5M9.98714 1C12.323 3.06658 15.3716 4.14257 18.4871 4C18.9407 5.54302 19.0795 7.16147 18.8953 8.75918C18.711 10.3569 18.2075 11.9013 17.4145 13.3005C16.6216 14.6998 15.5555 15.9254 14.2796 16.9045C13.0037 17.8836 11.5439 18.5962 9.98714 19C8.43035 18.5962 6.97062 17.8836 5.69468 16.9045C4.41875 15.9254 3.35265 14.6998 2.55973 13.3005C1.76682 11.9013 1.26327 10.3569 1.07901 8.75918C0.894756 7.16147 1.03355 5.54302 1.48714 4C4.60267 4.14257 7.65131 3.06658 9.98714 1Z"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'socials'
                                                            "
                                                            stroke="currentColor"
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 d="M10 5C9.46957 5 8.96086 4.78929 8.58579 4.41421C8.21071 4.03914 8 3.53043 8 3C8 2.46957 8.21071 1.96086 8.58579 1.58579C8.96086 1.21071 9.46957 1 10 1C10.5304 1 11.0391 1.21071 11.4142 1.58579C11.7893 1.96086 12 2.46957 12 3C12 3.53043 11.7893 4.03914 11.4142 4.41421C11.0391 4.78929 10.5304 5 10 5ZM10 5V9M10 9C10.7956 9 11.5587 9.31607 12.1213 9.87868C12.6839 10.4413 13 11.2044 13 12C13 12.7956 12.6839 13.5587 12.1213 14.1213C11.5587 14.6839 10.7956 15 10 15C9.20435 15 8.44129 14.6839 7.87868 14.1213C7.31607 13.5587 7 12.7956 7 12C7 11.2044 7.31607 10.4413 7.87868 9.87868C8.44129 9.31607 9.20435 9 10 9ZM4.69922 15.7998L7.49922 13.7998M15.3 15.7998L12.5 13.7998M1 17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19C3.53043 19 4.03914 18.7893 4.41421 18.4142C4.78929 18.0391 5 17.5304 5 17C5 16.4696 4.78929 15.9609 4.41421 15.5858C4.03914 15.2107 3.53043 15 3 15C2.46957 15 1.96086 15.2107 1.58579 15.5858C1.21071 15.9609 1 16.4696 1 17ZM15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17Z"
                                                                 :stroke="
                                                                      activeTab ==
                                                                      items.type
                                                                           ? '#FCDF79'
                                                                           : '#FFF'
                                                                 "
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                            />
                                                       </svg>
                                                       <svg
                                                            v-if="
                                                                 items.type ==
                                                                 'responsible-play'
                                                            "
                                                            class="w-5 lg:w-6 h-5 lg:h-6 me-3 xl:me-5 hidden xl:block"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                       >
                                                            <path
                                                                 stroke-linecap="round"
                                                                 stroke-linejoin="round"
                                                                 d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                                                            />
                                                       </svg>
                                                       <div
                                                            class="w-4 ml-3 xl:hidden"
                                                       ></div>
                                                       <span
                                                            class="text-sm lg:text-base"
                                                       >
                                                            {{ items.label }}
                                                       </span>
                                                       <div class="w-7"></div>
                                                  </span>
                                             </li>
                                        </ul>
                                   </div>
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/cow-with-logo.png'
                                        "
                                        alt="cow"
                                        class="mt-8 md:mt-16 mb-8 hidden xl:block"
                                   />
                              </div>
                         </div>
                         <div
                              class="mt-12 md:mt-13 lg:mt-0 h-full bg-white xl:w-8/12 2xl:w-9/12 px-5 md:px-8 xl:px-10 py-5 md:py-8 xl:py-10 flex flex-col justify-start rounded-xl rounded-t-none xl:rounded-l-none xl:rounded-r-xl"
                         >
                              <div
                                   v-if="activeTab == 'account-details'"
                                   class="w-full h-full"
                              >
                                   <Account
                                        :profileUpdate="profileUpdate"
                                        :loading="loading"
                                        :isUser="isUser"
                                   />
                              </div>
                              <div
                                   v-if="activeTab == 'payment-details'"
                                   class="w-full h-full"
                              >
                                   <Payment />
                              </div>

                              <div
                                   v-if="activeTab == 'coins'"
                                   class="w-full h-full flex flex-col justify-between"
                              >
                                   <div class="flex flex-col h-full">
                                        <div
                                             class="flex relative justify-end mb-5"
                                        >
                                             <button
                                                  class="w-full lg:w-60 border rounded-md px-2 py-3 flex justify-between items-center capitalize"
                                                  :class="
                                                       transactionMenu
                                                            ? 'border-background-2'
                                                            : 'border-black'
                                                  "
                                                  @click="
                                                       transactionMenu =
                                                            !transactionMenu
                                                  "
                                             >
                                                  <span class="capitalize"
                                                       >{{
                                                            filterLabel
                                                                 ? filterLabel
                                                                 : "All"
                                                       }}
                                                  </span>
                                                  <span
                                                       class="icon-arrow-down text-xs sort-trigger-down invert"
                                                  ></span>
                                             </button>
                                             <div
                                                  class="bg-white w-full lg:w-60 border border-black rounded-md flex-col items-end absolute mt-14"
                                                  :class="
                                                       transactionMenu
                                                            ? 'flex'
                                                            : 'hidden'
                                                  "
                                             >
                                                  <button
                                                       class="hover:bg-background-2 hover:text-white w-full text-left px-2 py-3"
                                                       v-for="(
                                                            data, key
                                                       ) in transaction_type"
                                                       :key="key"
                                                       @click="
                                                            transaction(data),
                                                                 (transactionMenu =
                                                                      !transactionMenu)
                                                       "
                                                  >
                                                       {{ data.label }}
                                                  </button>
                                             </div>
                                        </div>
                                        <Billings
                                             :tableLoading="tableLoading"
                                             :transaction_data="
                                                  transaction_data
                                             "
                                             :transaction="transaction"
                                             :pagination="pagination"
                                             :onPrevious="onPrevious"
                                             :onFirstPage="onFirstPage"
                                             :onFirstBtn="onFirstBtn"
                                             :onSecondBtn="onSecondBtn"
                                             :onThirdBtn="onThirdBtn"
                                             :onLastPage="onLastPage"
                                             :onNext="onNext"
                                        />
                                   </div>
                              </div>
                              <div
                                   v-if="activeTab == 'verification'"
                                   class="w-full h-full"
                              >
                                   <Verification
                                        :isUser="isUser"
                                        :kycStatus="kycStatus"
                                        :openUploadId="openUploadId"
                                   />
                              </div>
                              <div
                                   v-if="activeTab == 'security'"
                                   class="w-full h-full"
                              >
                                   <Security
                                        :loading="loading"
                                        :isUser="isUser"
                                   />
                              </div>
                              <div
                                   v-if="activeTab == 'socials'"
                                   class="w-full h-full"
                              >
                                   <Socials
                                        :social_linked="social_linked"
                                        :setSocialLink="setSocialLink"
                                   />
                              </div>
                              <div
                                   v-if="activeTab == 'responsible-play'"
                                   class="w-full h-full"
                              >
                                   <Responsible />
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div
               v-else-if="isSelfExclusion != null"
               class="min-h-screen -mt-16 flex justify-center items-center"
          >
               <SelfExclusionAlert />
          </div>
          <loading-overlay :isLoading="loading" />
     </div>
</template>

<script>
import { onMounted, onUnmounted, ref, reactive, computed } from "vue";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "@/store/auth";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter, useRoute } from "vue-router";
import { transaction_history } from "api/transaction.js";
import { setSocialLinked as setSocialLinkedAPI } from "api/auth.js";
import { useToaster } from "composables/use-toaster.js";
import _ from "underscore";
import { Head as InertiaHead } from "@inertiajs/vue3";
import Account from "./component/account.vue";
import Payment from "./component/payment.vue";
import Billings from "./component/billings.vue";
import Verification from "./component/verification.vue";
import Security from "./component/security.vue";
import Socials from "./component/socials.vue";
import Responsible from "./component/responsible.vue";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { utilitiesStore } from "store/utilities.js";

export default {
     components: {
          LoadingOverlay,
          InertiaHead,
          Account,
          Payment,
          Billings,
          Verification,
          Security,
          Socials,
          Responsible,
          SelfExclusionAlert
     },
     setup() {
          const { date6, money, eighteenYearsAgo } = useFormatter();
          const { open_kyc_navigation } = useKYC();

          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();

          const router = useRouter();
          const route = useRoute();
          const { scrollTop } = useScroll();

          const loading = ref(false);
          const tableLoading = ref(false);
          // const isAccount = ref(false);
          const transaction_data = ref([]);
          const pagination = reactive({
               data: [],
               currentPage: 1,
               itemsPerPage: 10,
               totalPage: 0
          });

          const { errorToast, infoToast } = useToaster();

          const totalData = ref("");

          const transactionMenu = ref(false);
          const fromQueryFilter = ref(false);

          const tabs = ref([
               { label: "Account Details", type: "account-details" },
               { label: "Payment Details", type: "payment-details" },
               { label: "Coins", type: "coins" },
               { label: "Verification", type: "verification" },
               { label: "Security", type: "security" },
               { label: "Socials", type: "socials" },
               { label: "Responsible Play", type: "responsible-play" }
          ]);

          const transaction_type = ref([
               { label: "All", transaction_type: undefined },
               { label: "GC Purchase", transaction_type: "deposit" },
               {
                    label: "Deposit Adjustment",
                    transaction_type: "deposit_adjustment"
               },
               { label: "Bonus", transaction_type: "bonus" },
               { label: "SC Redemption", transaction_type: "withdraw" },
               { label: "Expired SCC", transaction_type: "scc_expired" }
          ]);

          const filter = ref(null);
          const filterLabel = ref(null);

          const activeTab = ref(route.query.tab ?? "account-details");

          const profile_form = computed(() => {
               return useAuthStore.profile_form;
          });

          const set_phone_form = computed(() => {
               return useAuthStore.set_phone_form;
          });

          const selected_country = computed(() => {
               return useAuthStore.selected_country;
          });

          const social_linked = computed(() => {
               return useAuthStore.social_linked;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const kycStatus = computed(() => {
               return useAuthStore.kycStatus;
          });

          const isEmailVerified = computed(() => {
               return useAuthStore.isEmailVerified;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          let openMenu = ref(false);

          const resetProfileForm = () => {
               if (isUser.value["address"]) {
                    return;
               }
               useAuthStore.clearErrorsProfile();
          };

          const profileUpdate = () => {
               loading.value = true;
               if (set_phone_form.value) {
                    useAuthStore.updateProfileFormValue(
                         "phone",
                         "+" +
                              selected_country.value.dialCode +
                              set_phone_form.value
                    );
               } else {
                    useAuthStore.updateProfileFormValue("phone", "");
               }

               const data = {
                    ...profile_form.value,
                    address: {
                         ...profile_form.value.address,
                         state: profile_form.value.address.province_id,
                         city: profile_form.value.address.city_id,
                         zipcode: profile_form.value.address.zip_code
                    }
               };

               useAuthStore
                    .profileUpdate(data)
                    .then(async () => {
                         await useAuthStore.getUser(true);
                         loading.value = false;
                         useAuthStore.clearErrors();
                    })
                    .catch(() => {
                         loading.value = false;
                    });
          };

          const setSocialLink = async (type, connected) => {
               // under maintainance
               if (type == "facebook") {
                    infoToast("Meta Auth is under maintainance", "top-right");
                    return;
               }
               if (connected) {
                    return;
               }
               loading.value = true;

               await setSocialLinkedAPI({ type: type })
                    .then((res) => {
                         if (res) {
                              window.location.href = res.redirect;
                         }
                    })
                    .catch(() => {
                         errorToast("Error Social Link", "top-right");
                    });

               loading.value = false;
          };

          const openUploadId = () => {
               if (!isEmailVerified.value) {
                    eventBus.emit("open:verify_email");
                    // router.replace({ query: { modal: "email-verification" } });
               } else if (!kycStatus.value && !isUser.value["address"]) {
                    eventBus.emit("open:kyc");
                    router.replace({ query: { modal: "kyc" } });
               } else {
                    eventBus.emit("open:idverification");
                    router.replace({ query: { modal: "idverification" } });
               }
          };

          onMounted(async () => {
               scrollTop();
               useAuthStore.getSocialLinked();

               const query = route.query.filter;
               const params = {
                    page: 1,
                    itemsPerPage: 10
               };

               if (query) {
                    const filterType = _.find(
                         transaction_type.value,
                         function (params) {
                              return query == params["transaction_type"];
                         }
                    );

                    filter.value = filterType?.label;
                    filterLabel.value = filterType?.label;
                    params["transaction_type"] = query;

                    fromQueryFilter.value = true;
               }

               await getTransactionHistory(params);
               console.log(isSelfExclusion.value);

               if (isSelfExclusion.value) {
                    await useUtilitiesStore.getCoins();
               }
          });

          const getTransactionHistory = async (params) => {
               tableLoading.value = true;
               await transaction_history(params)
                    .then((data) => {
                         let arr = [];
                         data.data.map((d) => {
                              arr.push("");
                              arr.push(d);
                         });
                         transaction_data.value = arr;
                         // console.log(data);
                         pagination.totalPage = Math.ceil(
                              data.total / pagination.itemsPerPage
                         );

                         // console.log(pagination.totalPage);

                         totalData.value = data.total;
                         tableLoading.value = false;
                    })
                    .catch(() => {
                         transaction_data.value = [];
                         totalData.value = 0;
                         pagination.totalPage = 0;
                         tableLoading.value = false;
                    });
          };

          const onPrevious = async () => {
               pagination.currentPage != 1 ? pagination.currentPage-- : null;

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onFirstPage = async () => {
               pagination.currentPage = 1;

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onNext = async () => {
               pagination.currentPage != pagination.totalPage
                    ? pagination.currentPage++
                    : null;

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onLastPage = async () => {
               pagination.currentPage = pagination.totalPage;

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onFirstBtn = async () => {
               pagination.currentPage == 1
                    ? (pagination.currentPage = 1)
                    : (pagination.currentPage == pagination.totalPage &&
                             pagination.totalPage < 2) ||
                        (pagination.currentPage == pagination.totalPage &&
                             pagination.currentPage != 2)
                      ? (pagination.currentPage = pagination.currentPage - 2)
                      : (pagination.currentPage = pagination.currentPage - 1);

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onSecondBtn = async () => {
               const current = pagination.currentPage;
               pagination.currentPage == 1
                    ? (pagination.currentPage = pagination.currentPage + 1)
                    : (pagination.currentPage == pagination.totalPage &&
                             pagination.totalPage < 2) ||
                        (pagination.currentPage == pagination.totalPage &&
                             pagination.currentPage != 2)
                      ? (pagination.currentPage = pagination.currentPage - 1)
                      : (pagination.currentPage = current);

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const onThirdBtn = async () => {
               const current = pagination.currentPage;

               pagination.currentPage == 1
                    ? (pagination.currentPage = pagination.currentPage + 2)
                    : pagination.currentPage == pagination.totalPage
                      ? (pagination.currentPage = current)
                      : (pagination.currentPage = pagination.currentPage + 1);

               const params = {
                    page: pagination.currentPage,
                    itemsPerPage: 10,
                    transaction_type: filter.value?.toLowerCase()
               };

               await getTransactionHistory(params);
          };

          const transaction = async (e) => {
               filter.value = e.transaction_type;
               filterLabel.value = e.label;
               const params = {
                    page: 1,
                    itemsPerPage: 10
               };

               if (filter.value != "all") {
                    params["transaction_type"] = e.transaction_type;
               }

               pagination.currentPage = 1;

               await getTransactionHistory(params);
          };

          const setTab = async (tab) => {
               if (tab == "payment-details") {
                    if (open_kyc_navigation("from_get_coins") == true) {
                         return;
                    }
               }

               activeTab.value = tab;

               if (window.innerWidth < 1280) {
                    openMenu.value = !openMenu.value;
               }

               if (tab == "account-details") {
                    resetProfileForm();
               }

               if (tab == "coins") {
                    if (fromQueryFilter.value == true) {
                         filter.value = null;
                         filterLabel.value = null;
                         fromQueryFilter.value = false;

                         const params = {
                              page: 1,
                              itemsPerPage: 10
                         };

                         params["transaction_type"] = "";

                         await getTransactionHistory(params);
                    }
               }

               router.replace({ query: { tab: tab } });
          };

          onUnmounted(() => {
               eventBus.off("open:myaccount");
          });

          window.addEventListener("resize", () => {
               openMenu.value = false;
          });

          return {
               isUser,
               loading,
               activeTab,
               date6,
               money,
               eighteenYearsAgo,
               openUploadId,
               profileUpdate,
               tableLoading,
               transaction_data,
               transaction_type,
               transactionMenu,
               pagination,
               transaction,
               onPrevious,
               onFirstPage,
               onNext,
               onLastPage,
               onFirstBtn,
               onSecondBtn,
               onThirdBtn,
               social_linked,
               setSocialLink,
               resetProfileForm,
               openMenu,
               setTab,
               filter,
               filterLabel,
               tabs,
               kycStatus,
               baseUrl,
               isSelfExclusion
          };
     }
};
</script>
