<template>
     <div
          class="w-max lg:px-5 h-10 lg:h-full bg-moozi-2 rounded-full flex cursor-pointer relative border border-solid border-moozi-4"
     >
          <div
               @click="onSortByOpen()"
               class="flex flex-row gap-3 justify-center items-center w-10 lg:w-full sort-trigger"
          >
               <svg
                    class="w-4"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M3 9C2.46957 9 1.96086 8.78929 1.58579 8.41421C1.21071 8.03914 1 7.53043 1 7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5M3 9C3.53043 9 4.03914 8.78929 4.41421 8.41421C4.78929 8.03914 5 7.53043 5 7C5 6.46957 4.78929 5.96086 4.41421 5.58579C4.03914 5.21071 3.53043 5 3 5M3 9V17M3 5V1M9 15C8.46957 15 7.96086 14.7893 7.58579 14.4142C7.21071 14.0391 7 13.5304 7 13C7 12.4696 7.21071 11.9609 7.58579 11.5858C7.96086 11.2107 8.46957 11 9 11M9 15C9.53043 15 10.0391 14.7893 10.4142 14.4142C10.7893 14.0391 11 13.5304 11 13C11 12.4696 10.7893 11.9609 10.4142 11.5858C10.0391 11.2107 9.53043 11 9 11M9 15V17M9 11V1M15 6C14.4696 6 13.9609 5.78929 13.5858 5.41421C13.2107 5.03914 13 4.53043 13 4C13 3.46957 13.2107 2.96086 13.5858 2.58579C13.9609 2.21071 14.4696 2 15 2M15 6C15.5304 6 16.0391 5.78929 16.4142 5.41421C16.7893 5.03914 17 4.53043 17 4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2M15 6V17M15 2V1"
                         stroke="white"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                    />
               </svg>
               <p
                    class="font-roboto font-medium text-white text-sm xl:text-base capitalize hidden lg:block sort-trigger-text text-nowrap overflow-hidden"
               >
                    Sort By: {{ removeUnderScore(params.sortType) }}
               </p>
          </div>
          <div
               v-if="sortByOpen"
               class="absolute z-20 top-12 bg-moozi-2 flex flex-col justify-start gap-1 lg:gap-2 rounded-xl sort-wrapper w-28 lg:w-32 xl:w-full right-0 border border-solid border-moozi-4"
          >
               <div
                    @click="() => onSort('release_date')"
                    class="sort-list flex flex-row gap-3 items-center px-3 py-2"
               >
                    <span
                         :class="
                              params.sortType == 'release_date'
                                   ? ''
                                   : 'invisible'
                         "
                         class="icon-check-stroke text-xxs sort-check"
                    ></span>
                    <p
                         class="font-roboto font-normal text-white text-sm lg:text-base sort-text"
                    >
                         Release Date
                    </p>
               </div>
               <div
                    @click="() => onSort('a-z')"
                    class="sort-list flex flex-row gap-3 items-center px-3 py-2"
               >
                    <span
                         :class="params.sortType == 'a-z' ? '' : 'invisible'"
                         class="icon-check-stroke text-xxs sort-check"
                    ></span>
                    <p
                         class="font-roboto font-normal text-white text-sm lg:text-base sort-text"
                    >
                         Title: A-Z
                    </p>
               </div>
               <div
                    @click="() => onSort('z-a')"
                    class="sort-list flex flex-row gap-3 items-center px-3 py-2"
               >
                    <span
                         :class="params.sortType == 'z-a' ? '' : 'invisible'"
                         class="icon-check-stroke text-xxs sort-check"
                    ></span>
                    <p
                         class="font-roboto font-normal text-white text-sm lg:text-base sort-text"
                    >
                         Title: Z-A
                    </p>
               </div>
               <div
                    @click="() => onSort('popularity')"
                    class="sort-list flex flex-row gap-3 items-center px-3 py-2"
               >
                    <span
                         :class="
                              params.sortType == 'popularity' ? '' : 'invisible'
                         "
                         class="icon-check-stroke text-xxs sort-check"
                    ></span>
                    <p
                         class="font-roboto font-normal text-white text-sm lg:text-base sort-text"
                    >
                         Popular
                    </p>
               </div>
          </div>
     </div>
</template>
<script>
import { onMounted } from "vue";

export default {
     props: ["params", "sortByOpen", "onSortByOpen", "onSort"],
     setup(props) {
          onMounted(async () => {
               document.addEventListener("click", (evt) => {
                    const sortTrigger1 =
                         document.querySelector(".sort-wrapper");
                    const sortTrigger2 = document.querySelector(".sort-list");
                    const sortTrigger3 = document.querySelector(".sort-check");
                    const sortTrigger4 = document.querySelector(".sort-text");
                    const sortTrigger5 =
                         document.querySelector(".sort-trigger");
                    const sortTrigger6 =
                         document.querySelector(".sort-trigger-text");
                    const sortTrigger7 =
                         document.querySelector(".sort-trigger-down");

                    let targetElSort = evt.target; // clicked element

                    if (targetElSort != null) {
                         do {
                              if (
                                   targetElSort == sortTrigger1 ||
                                   targetElSort == sortTrigger2 ||
                                   targetElSort == sortTrigger3 ||
                                   targetElSort == sortTrigger4 ||
                                   targetElSort == sortTrigger5 ||
                                   targetElSort == sortTrigger6 ||
                                   targetElSort == sortTrigger7
                              ) {
                                   return;
                              }
                              targetElSort = targetElSort.parentNode;
                         } while (targetElSort);
                    }

                    props.onSortByOpen(false);
               });
          });

          let removeUnderScore = (val) => {
               return val.replace(/_/g, " ");
          };

          return {
               removeUnderScore
          };
     }
};
</script>
