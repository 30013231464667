<template>
     <div v-if="data?.data?.length != 0">
          <div class="flex flex-col -mt-2 mb-5 md:mb-10">
               <div class="flex flex-row items-center justify-between">
                    <div
                         v-if="!loading && data?.data"
                         class="flex flex-row items-center gap-2 text-white lobby-category-clickable hover:text-moozi-3 cursor-pointer duration-300 ease-in-out"
                         @click="onSetActive(game_type)"
                    >
                         <slot name="icon"></slot>
                         <p
                              class="font-roboto font-bold text-sm lg:text-lg capitalize"
                         >
                              {{ title }}
                         </p>
                    </div>
                    <Skeletor
                         v-else
                         as="div"
                         class="!w-32 lg:!w-44 !h-6 lg:!h-7 !rounded-md mx-1.5"
                    />
                    <div
                         v-if="!loading"
                         class="flex flex-row gap-2"
                    >
                         <div
                              @click="onPrev"
                              :class="
                                   currentSlide > 0
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="23"
                                   height="23"
                                   viewBox="0 0 23 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide > 0
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z"
                                   />
                              </svg>
                         </div>
                         <div
                              v-if="
                                   !loadingSeeMoreGames &&
                                   !loadingSortGames &&
                                   !loadingDropwdownGames
                              "
                              @click="onNext"
                              :class="
                                   currentSlide < nextLimit
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="22"
                                   height="23"
                                   viewBox="0 0 22 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide < nextLimit
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z"
                                   />
                              </svg>
                         </div>
                         <div v-else>
                              <svg
                                   aria-hidden="true"
                                   class="w-5 h-5 text-primal-black animate-spin fill-primal-yellow"
                                   viewBox="0 0 100 101"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                   ></path>
                                   <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                   ></path>
                              </svg>
                         </div>
                    </div>
               </div>
               <div class="mt-2">
                    <Carousel
                         v-if="
                              !loadingSortGames &&
                              !loadingDropwdownGames &&
                              !loading &&
                              data?.data
                         "
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="true"
                         :touchDrag="true"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItems"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="item in data?.data"
                              :key="item.id"
                              :game-id="item.id"
                         >
                              <div
                                   @click="() => onRedirect(item)"
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <div
                                             v-if="game_type == 'new'"
                                             class="absolute top-0 left-0 z-10 w-max h-max"
                                        >
                                             <div
                                                  class="bg-moozi-12 rounded-tl-md rounded-br-md capitalize flex justify-center items-center px-1.5 md:px-2 py-0.5 md:py-1 font-roboto font-bold text-white text-base lg:text-base"
                                             >
                                                  new
                                             </div>
                                        </div>
                                        <div
                                             v-if="
                                                  game_type == 'moozi-exclusive'
                                             "
                                             class="absolute top-0 left-0 z-10 w-max h-max"
                                        >
                                             <div
                                                  class="bg-moozi-13 rounded-tl-md rounded-br-md capitalize flex justify-center items-center px-1.5 md:px-2 py-0.5 md:py-1 font-roboto font-bold text-white text-base lg:text-base"
                                             >
                                                  exclusive
                                             </div>
                                        </div>
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             :alt="
                                                  getProvider(
                                                       item.provider_id
                                                  ) +
                                                  '-' +
                                                  item.game_pic
                                             "
                                             class="broken-image absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                        >
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic != null &&
                                                       !loading
                                                  "
                                                  :src="item.game_pic"
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-if="
                                                       item.game_pic_mobile !=
                                                            null && !loading
                                                  "
                                                  :src="item.game_pic_mobile"
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                                  @error="onImageError"
                                             />
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/loader/games-loader.png'
                                                  "
                                                  :alt="
                                                       getProvider(
                                                            item.provider_id
                                                       ) +
                                                       '-' +
                                                       item.game_pic
                                                  "
                                                  class="thumbnail-image rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit"
                                             />
                                        </div>
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
                    <Carousel
                         v-else
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItems"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="(item, key) in 8"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 transition-transform duration-300 ease-in-out"
                              >
                                   <div
                                        class="games-aspect-ratio-1-1 relative items-center cursor-pointer"
                                   >
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/loader/games-loader.png'
                                             "
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails invisible"
                                        />
                                        <Skeletor
                                             as="div"
                                             class="!absolute !top-0 !left-0 !rounded-md !w-full !h-full"
                                        />
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
               </div>
          </div>
     </div>
</template>
<script>
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";
import { ref, onMounted, computed, watch } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";
import { Carousel, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";
import eventBus from "plugins/event.js";

export default {
     components: {
          Carousel,
          Slide
     },
     props: [
          "data",
          "title",
          "overideOnlick",
          "onNextSlide",
          "pagination",
          "game_type",
          "onSetActive"
     ],
     setup(props) {
          const useGamesStore = gamesStore();

          const router = useRouter();

          const loading = ref(false);

          const currentSlide = ref(0);
          const showItems = ref({
               0: {
                    itemsToShow: 3
               },
               1024: {
                    itemsToShow: 4
               },
               1280: {
                    itemsToShow: 6
               },
               1536: {
                    itemsToShow: 7
               }
          });

          const nextLimit = ref(0);
          const voidWatch = ref(false);

          const { onImageError } = useImage();

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const loadingSeeMoreGames = computed(() => {
               return (
                    useGamesStore.loadingSeeMoreGames[props.game_type] || null
               );
          });

          const loadingSortGames = computed(() => {
               return useGamesStore.loadingSortGames[props.game_type] || null;
          });

          const loadingDropwdownGames = computed(() => {
               return (
                    useGamesStore.loadingDropwdownGames[props.game_type] || null
               );
          });

          const onNext = async () => {
               const current = currentSlide.value;

               if (props.data.data) {
                    const total = Math.round(props.data.data.length / 3);
                    const slideIndex = Math.round((current + 3) / 3);

                    if (total - 3 == slideIndex) {
                         if (
                              props.pagination?.current < props.pagination?.last
                         ) {
                              await props.onNextSlide();
                         }
                    }
               }

               if (current < nextLimit.value) {
                    currentSlide.value = current + 3;
               }
          };

          const onPrev = () => {
               const current = currentSlide.value;
               if (current > 0) {
                    currentSlide.value = current - 3;
               }
          };

          const adjustNextLimit = () => {
               window.addEventListener("resize", () => {
                    currentSlide.value = 0;
                    setNextLimit();
               });
          };

          const setNextLimit = () => {
               const getWidth = window.innerWidth;

               const find = _.filter(
                    Object.keys(showItems.value),
                    function (params) {
                         return getWidth >= parseInt(params);
                    }
               );

               let size = parseInt(find.pop());
               size = Math.round(showItems.value[size]["itemsToShow"]);

               nextLimit.value =
                    size >= props.data?.data?.length
                         ? 0
                         : props.data?.data?.length - size;
          };

          const getProvider = (id) => {
               const find = _.find(providerData.value, (params) => {
                    return params["id"] == id;
               });

               return find ? find["name"] : "";
          };

          const onRedirect = (data) => {
               if (props.overideOnlick) {
                    props?.overideOnlick(data.game_name, data.id);
                    return;
               }

               openGamePreview(data, () => {
                    router.push({
                         path:
                              "/games/detail/" +
                              encodeURIComponent(
                                   data.game_name.replaceAll(" ", "-")
                              ) +
                              "-" +
                              data.id
                    });
               });
          };

          const openGamePreview = (data, onPlay) => {
               eventBus.emit("open:gamepreview", {
                    data: data,
                    onPlay: onPlay
               });
          };

          onMounted(async () => {
               setNextLimit();
               adjustNextLimit();
          });

          watch(props.data, (newVal) => {
               if (newVal) {
                    setNextLimit();
               }
               if (newVal && voidWatch.value == false) {
                    loading.value = true;
                    setTimeout(() => {
                         loading.value = false;
                         voidWatch.value = true;
                    }, 2000);
               }
          });

          return {
               baseUrl,
               onNext,
               onPrev,
               currentSlide,
               showItems,
               nextLimit,
               getProvider,
               loading,
               onRedirect,
               onImageError,
               openGamePreview,
               loadingSeeMoreGames,
               loadingSortGames,
               loadingDropwdownGames
          };
     }
};
</script>
